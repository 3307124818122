import React from "react";

import { Link } from "react-router-dom";

import { MdCampaign } from "react-icons/md";

import {
  CampaignsFullContainer,
  CampaignsContainer,
  CampaignsCard,
  CampaignsBody,
  CampaignsTitle,
  CampaignsBeforeTitle,
} from "../styles/campaigns";

import { YellowTitle } from "../styles/styles";

import snickers from "../assets/video/snickers.mp4";
import sofi_fer from "../assets/video/sofi_fer.mp4";
import doritos from "../assets/video/doritos.mp4";
import mel_de_haene from "../assets/video/mel_de_haene.mp4";
import ana_tena from "../assets/video/ana_tena.mp4";
import mafer_rocha from "../assets/video/mafer_rocha.mp4";
import amairani_garza from "../assets/video/amairani_garza.mp4";
import paola_rojas from "../assets/video/paola_rojas.mp4";
import capi_perez from "../assets/video/capi_perez.mp4";
import karol_sevilla from "../assets/video/karol_sevilla.mp4";
import hermanos_skabeche from "../assets/video/hermanos_skabeche.mp4";

import Asset_4 from "../assets/images/Asset 4.svg";
import Asset_7 from "../assets/images/Asset 7.svg";

import { useAtom } from "jotai";
import { selectedCampaignAtom } from "../store/store";

import "animate.css";

import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();

const campaigns = [
  {
    id: 1,
    video: snickers,
    date: "31/03/2022",
    name: "Snickers",
    slug: "snickers",

    items: [
      {
        type: "video",
        url: "https://chatarrainfluencer-mx.sfo3.cdn.digitaloceanspaces.com/snickers.mp4",
        name: "Snickers",
        video: snickers,
        date: "31/03/2022",
        text: "La cuenta de Instagram de Snickers en México es muestra de la falta de entendimiento de lo que es la publicidad transparente e identificable. Documentamos cómo esta marca de chocolates compartió en su cuenta de Instagram oficial publicidad engañosa de los influencers Nabile Ahumada (@nabileahumada, 358.0 mil seguidores), Pico Navarro (@piconavarrobeats, 16.4 mil seguidores) y Mauricio Olmedo (@elmauri, 20.6 mil seguidores) el 31 de marzo del 2022.",
        footnote:
          "Grabación de pantalla de la cuenta de Instagram de Snickers tomada el 31.03.2022",
      },
    ],
  },
  {
    id: 2,
    video: mafer_rocha,
    date: "24/10/2021",
    name: "Helados Nestlé",
    slug: "helados_nestle",

    items: [
      {
        type: "video",
        url: "https://chatarrainfluencer-mx.sfo3.cdn.digitaloceanspaces.com/mafer_rocha.mp4",
        name: "Helados Nestlé",
        video: mafer_rocha,
        date: "24/10/2021",
        text: "En el estudio documentamos una campaña de publicidad engañosa a través de influencers por parte de HELADOS NESTLÉ en México. En este video la influencer Mafer Rocha, quien se hace llamar “Streamer”, quería crear empatía con la marca el 24 de octubre del del 2021 en su cuenta de Tik Tok (1.6 millones de seguidores).",
        footnote:
          "Grabación de pantalla de la cuenta de TikTok de Mafer Rocha tomada el 24.10.2021",
      },
    ],
  },
  {
    id: 3,
    video: karol_sevilla,
    date: "23/07/2021",
    name: "Choco Krispis",
    slug: "choco_krispis",

    items: [
      {
        type: "video",
        url: "https://chatarrainfluencer-mx.sfo3.cdn.digitaloceanspaces.com/karol_sevilla.mp4",
        name: "Choco Krispis",
        video: karol_sevilla,
        date: "23/07/2021",
        text: "La mega influencer Karol Sevilla (@karolsevillaokay, 26.5 millones de seguidores en TikTok) compuso una canción para el cereal Choco Krispis de la empresa multinacional de cereales Kellogg’s. En TikTok la coreografía y la canción hizo mucho eco, tanto que hay una docena de videos replicando la coreografía y canción de la influencer. Lo bueno: etiquetó el video con #Ad.",
        footnote:
          "Grabación de pantalla de la cuenta de TikTok de Mafer Rocha tomada el 23.07.2021",
      },
    ],
  },
  {
    id: 4,
    video: mel_de_haene,
    date: "16/09/2021",
    name: "Danonino",
    slug: "danonino",

    items: [
      {
        type: "video",
        url: "https://chatarrainfluencer-mx.sfo3.cdn.digitaloceanspaces.com/mel_de_haene.mp4",
        name: "Danonino",
        video: mel_de_haene,
        date: "16/09/2021",
        text: "La marca Danonino del gigante multinacional agroalimentario de origen francés Grupo Danone es uno de los peores ejemplos de publicidad engañosa a través de influencers en México. El 16 de septiembre del 2021 la empresa compartió un video de la mamá influencer Mel de Haene (@melsinletraschiquitas, @174 mil seguidores), quien, además, para sus fines comerciales, expone la privacidad de su hijo.",
        footnote:
          "Grabación de pantalla de la cuenta de Instagram de Mel de Haene tomada el 16.09.2021",
      },
    ],
  },
  {
    id: 5,
    video: sofi_fer,
    date: "15/03/2022",
    name: "Krispy Kream",
    slug: "krispykream",

    items: [
      {
        type: "video",
        url: "https://chatarrainfluencer-mx.sfo3.cdn.digitaloceanspaces.com/sofi_fer.mp4",
        name: "Krispy Kream",
        video: sofi_fer,
        date: "15/03/2022",
        text: "KrispyKream, junto con Distroller (marca de juguetes), hicieron una campaña engañosa de publicidad, utilizando influencers infantiles. La influencer Sofí Fer (@juguetesdesofifer, 20.9 mil seguidores) cuya cuenta está “manejada por papá @tapiajr” invitó a sus seguidores a comprar su paquete de donas de la edición Distroller con sus amigos de KrispyKream.",
        footnote:
          "Grabación de pantalla de la cuenta de Instagram de Sofifer tomada el 15.03.2022",
      },
    ],
  },
  {
    id: 6,
    video: ana_tena,
    name: "McDonald´s",
    slug: "mcdonalds",

    items: [
      {
        type: "video",
        url: "https://chatarrainfluencer-mx.sfo3.cdn.digitaloceanspaces.com/ana_tena.mp4",
        name: "McDonald´s",
        video: ana_tena,
        date: "31/03/2022",
        text: "McDonald´s no se queda atrás en esta tendencia de marketing. En su campaña engañosa de publicidad conocida como “combinación perfecta”, la influencer Ana Tena (6.5 millones de seguidores) en su cuenta de TikTok hace alusión a una combinación perfecta entre ella y su novio, y la compra con la combinación perfecta de McDonalds: una hamburguesa de pollo con pan hecho a base de papá.",
        footnote:
          "Video Grabación de pantalla de la cuenta de Instagram de Ana Tena tomada el 1.04.2022",
      },
    ],
  },
  {
    id: 7,
    video: hermanos_skabeche,
    date: "08/11/2022",
    name: "Fanta",
    slug: "fanta",

    items: [
      {
        type: "video",
        url: "https://chatarrainfluencer-mx.sfo3.cdn.digitaloceanspaces.com/hermanos_skabeche.mp4",
        name: "Fanta",
        video: hermanos_skabeche,
        date: "08/11/2022",
        text: "Fanta a través de los influencers hermanos Bryan Skabeche (@bryan_skabeche, 4.0 millones de seguidores) y Eddy Skabeche (@eddy_skabeche, 2.6 millones de seguidores) promocionó su nuevo refresco. Los hermanos influencers pedían a sus seguidores hacer una dinámica con la marca para que les regalaran una Fanta.",
        footnote:
          "Grabación de pantalla de la cuenta de Instagram de Fanta tomada el 08.11.2022",
      },
    ],
  },
  {
    id: 8,
    video: doritos,
    date: "21/11/2022",
    name: "Doritos",
    slug: "doritos",

    items: [
      {
        type: "video",
        url: "https://chatarrainfluencer-mx.sfo3.cdn.digitaloceanspaces.com/doritos.mp4",
        name: "Doritos",
        video: doritos,
        date: "21/11/2022",
        text: "La fritura Doritos se vale de festivales e influencers para posicionar sus productos frente a adolescentes y jóvenes.  En el Corona Capital, diversos influencers como Miranda Ibañez (@miranda_ibanez, 1.2 millones de seguidores) o Mauricio Abad (@soymauricioabad, 579 mil seguidores) presumieron su paquete de Doritos en el festival e invitaban a sus seguidores a visitar el stand de la marca en el Corona Capital.",
        footnote:
          "Grabación de pantalla de la cuenta de Instagram de Doritos tomada el 21.11.2022",
      },
    ],
  },
];

function Campaigns() {
  const [selectedCampaign, setSelectedCampaign] = useAtom(selectedCampaignAtom);

  return (
    <CampaignsFullContainer id="campaigns">
      <div
        data-aos="zoom-in-left"
        data-aos-duration="1000"
        className="w-[80px] lg:w-[120px] active:w-[70px] active:lg:w-[110px] invisible md:visible absolute right-[20px] lg:right-[60px] 2xl:right-[150px] z-40"
      >
        <img
          className="animate__pulse animate__infinite animate__animated animate__fast"
          src={Asset_4}
          alt="Chatarra Influencer Illustration"
        />
      </div>
      <div
        data-aos="zoom-in-right"
        data-aos-duration="1000"
        className="w-[80px] lg:w-[120px] active:w-[70px] active:lg:w-[110px] invisible md:visible absolute left-[20px] lg:left-[60px] 2xl:left-[120px] bottom-[0px] z-40"
      >
        <img
          className="animate__pulse animate__infinite animate__animated animate__fast"
          src={Asset_7}
          alt="Chatarra Influencer Illustration"
        />
      </div>
      <YellowTitle>CAMPAÑAS #CHATARRAINFLUENCERS</YellowTitle>
      <CampaignsContainer>
        {campaigns.map(c => (
          <Link to={`campana/${c.slug}`} onClick={() => setSelectedCampaign(c)}>
            <CampaignsCard>
              <figure className="h-[200px] relative">
                <video
                  className="absolute top-0 left-0 overflow-hidden w-full h-auto object-cover"
                  loop
                  muted
                  playsInline
                >
                  <source src={c.video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </figure>
              <CampaignsBody>
                <CampaignsBeforeTitle>{c.date}</CampaignsBeforeTitle>
                <CampaignsTitle>{c.name}</CampaignsTitle>
              </CampaignsBody>
            </CampaignsCard>
          </Link>
        ))}
      </CampaignsContainer>
    </CampaignsFullContainer>
  );
}

export default Campaigns;
