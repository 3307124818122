import React, { useEffect } from "react";

import {
  InfluencersContainer,
  InfluencersFeaturedCardContainer,
  InfluencersFeaturedCard,
  InfluencersFeaturedCardTitle,
  InfluencersFeaturedCardHighlightTitle,
  InfluencersLink,
} from "../styles/influencers";

import { IoMdHeart, IoIosSend } from "react-icons/io";
import { IoBookmarkSharp } from "react-icons/io5";

import chin_illustration from "../assets/images/chin-illustration.png";
import Influencer_RECUADRO from "../assets/images/Influencer_RECUADRO.png";
import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();

function Influencers() {
  useEffect(() => {});

  const animate = () => {
    //console.log("animado quedaste");
  };

  return (
    <InfluencersContainer>
      <div className="flex flex-col justify-center items-center relative z-40">
        <InfluencersFeaturedCardContainer
          data-aos="zoom-in-up"
          data-aos-duration="1000"
        >
          <InfluencersFeaturedCard>
            <InfluencersFeaturedCardTitle>
              INFLUENCERS, PUBLICIDAD Y{" "}
              <InfluencersFeaturedCardHighlightTitle>
                COMIDA CHATARRA
              </InfluencersFeaturedCardHighlightTitle>
            </InfluencersFeaturedCardTitle>
            <figure className="">
              <img
                className="rounded-none"
                src={Influencer_RECUADRO}
                alt="Album"
              />
            </figure>
            <div className="card-body pt-0 pb-4 flex flex-col items-center justify-center">
              <div className="w-full flex flex-row justify-start text-4xl">
                <IoMdHeart
                  className="hover:cursor-pointer transition ease-in-out delay-150 hover:scale-110 duration-200 mx-1 text-stone-200 hover:text-chinred active:text-chinred"
                  onClick={() => animate()}
                />
                <IoIosSend className="hover:cursor-pointer transition ease-in-out delay-150 hover:scale-110 duration-200 mx-1 text-stone-200 hover:text-chinyellow active:text-chinyellow" />
                <IoBookmarkSharp className="hover:cursor-pointer transition ease-in-out delay-150 hover:scale-110 duration-200 mx-1 text-stone-200 hover:text-chinpurple active:text-chinpurple" />
              </div>
            </div>
          </InfluencersFeaturedCard>
        </InfluencersFeaturedCardContainer>
        <div className="mx-5 sm:mx-20 lg:mx-40 xl:mx-80 2xl:mx-96 pt-20 text-justify">
          <p className=" pt-3 text-lg font-medium leading-6">
            La investigación evidenció por primera vez en México de forma amplia
            cómo empresas y agencias de marketing usan a las y los influencers
            para campañas de publicidad engañosa de comida chatarra. Nuestro
            análisis comparativo de la normatividad documentó que estas
            empresas, en estrecha cooperación con las y los influencers,
            implementan este tipo de campañas de publicidad engañosa en un
            contexto casi anárquico. Los actores de la industria de comida de
            chatarra y de publicidad ignoran todas las reglas, leyes y normas de
            publicidad tradicionales, siendo la publicidad a través de
            influencers infantiles un ejemplo sumamente grave. En México, las y
            los influencers pueden promocionar cualquier producto o servicio sin
            reglas, normas y leyes, y mucho menos tienen temor por sanciones o
            investigaciones por parte de las autoridades competentes,
            especialmente por parte de la PROFECO.
            <br />
            <br />
            Con base en nuestras{" "}
            <InfluencersLink href="/assets/pdf/DGP.pdf" target="_blank">
              solicitudes de acceso a la información
            </InfluencersLink>{" "}
            sabemos que en 2019 y 2020 la PROFECO monitoreó solamente 8 cuentas
            de Instagram, y de enero a octubre del 2022 apenas 26 cuentas. En
            TikTok y YouTube la PROFECO está ausente completamente.
            <br />
            <br />
            En México estamos todavía a inicios de una discusión pública sobre
            las reglas y la responsabilidad social que tienen las y los
            influencers para promocionar productos de comida chatarra. Esperamos
            impulsar dicha discusión con el estudio y con el observatorio
            #ChatarraInfluencer.
          </p>
        </div>
      </div>
    </InfluencersContainer>
  );
}

export default Influencers;
