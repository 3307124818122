import styled, { keyframes } from "styled-components";
import { pulse } from "react-animations";

const pulseAnimation = keyframes`${pulse}`;

/* Influencers */

export const InfluencersContainer = styled.div.attrs({
  id: "influencers", className: "Influencers pt-10 relative",
})``;

export const InfluencersShadowImage = styled.div.attrs({
  className: "absolute top-0 bottom-0 left-0 right-0 z-30",
})`
   {
    /* ff 3.6+ */
    background: -moz-linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.7) 0%,
      rgba(0, 0, 0, 0.3) 10%,
      rgba(255, 255, 255, 1) 21%,
      rgba(255, 255, 255, 1) 50%,
      rgba(0, 0, 0, 0.2) 65%,
      rgba(0, 0, 0, 0.87) 90%,
      rgba(0, 0, 0, 1) 100%
    );

    /* safari 5.1+,chrome 10+ */
    background: -webkit-linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.7) 0%,
      rgba(0, 0, 0, 0.3) 10%,
      rgba(255, 255, 255, 1) 21%,
      rgba(255, 255, 255, 1) 50%,
      rgba(0, 0, 0, 0.2) 65%,
      rgba(0, 0, 0, 0.87) 90%,
      rgba(0, 0, 0, 1) 100%
    );

    /* opera 11.10+ */
    background: -o-linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.7) 0%,
      rgba(0, 0, 0, 0.3) 10%,
      rgba(255, 255, 255, 1) 21%,
      rgba(255, 255, 255, 1) 50%,
      rgba(0, 0, 0, 0.2) 65%,
      rgba(0, 0, 0, 0.87) 90%,
      rgba(0, 0, 0, 1) 100%
    );

    /* ie 6-9 */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=1 );

    /* ie 10+ */
    background: -ms-linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.7) 0%,
      rgba(0, 0, 0, 0.3) 10%,
      rgba(255, 255, 255, 1) 21%,
      rgba(255, 255, 255, 1) 50%,
      rgba(0, 0, 0, 0.2) 65%,
      rgba(0, 0, 0, 0.87) 90%,
      rgba(0, 0, 0, 1) 100%
    );

    /* global 94%+ browsers support */
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.7) 0%,
      rgba(0, 0, 0, 0.3) 10%,
      rgba(255, 255, 255, 1) 21%,
      rgba(255, 255, 255, 1) 50%,
      rgba(0, 0, 0, 0.2) 65%,
      rgba(0, 0, 0, 0.87) 90%,
      rgba(0, 0, 0, 1) 100%
    );
  }
`;

export const InfluencersFeaturedCardContainer = styled.div.attrs({
  className: "w-11/12 sm:w-8/12 md:w-1/2 lg:w-4/12 xl:w-3/12 mt-12",
})``;

export const InfluencersFeaturedCard = styled.div.attrs({
  className: "card  bg-base-100 shadow-xl rounded-none",
})`
  // :hover {
  //   animation: 0.1s ${pulseAnimation};
  //   cursor: poBarlow;
  }
`;

export const InfluencersFeaturedCardTitle = styled.h2.attrs({
  className:
    "card-title mx-6 mt-10 uppercase font-bold items-center text-3xl text-chinblack italic text-center inline-block",
})`
  letter-spacing: 0.015em;
`;

export const InfluencersFeaturedCardHighlightTitle = styled.span.attrs({
  className: "uppercase text-chinpurple inline-block",
})`
  letter-spacing: 0.015em;
`;

export const InfluencersFeaturedCardUsername = styled.p.attrs({
  className: "text-left",
})`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 120% */

  display: flex;
  align-items: center;
  letter-spacing: 0.015em;

  color: #0d56ea;
`;

export const InfluencersFeaturedCardPrice = styled.p.attrs({
  className: "w-3/12 self-start justify-self-end text-right",
})`
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 34px;
  /* identical to box height, or 189% */

  letter-spacing: 0.015em;

  color: #000000;
`;

export const InfluencersLink = styled.a.attrs({
  className: "text-chinpurple underline underline-offset-2 hover:text-black normal-case p-0",
})`
`;
