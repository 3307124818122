import React from "react";

import {
  LiteratureContainer,
  LiteratureText,
  LiteratureIllustrationContainer,
  LiteratureDownloadButton,
} from "../styles/literature";

import { RedTitle } from "../styles/styles";

import { MdDownload } from "react-icons/md";

import chin_illustration from "../assets/images/chin-illustration.png";

import Influencer_Complementaria_1 from "../assets/images/Influencer_Complementaria_1.png";
import Influencer_Complementaria_2 from "../assets/images/Influencer_Complementaria_2.png";

import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init();

function Literature() {
  return (
    <LiteratureContainer>
      <div className="flex justify-center relative z-40">
        <RedTitle>EVIDENCIA CIENTÍFICA</RedTitle>
      </div>
      <div className="flex flex-col justify-center items-center mx-5 sm:mx-20 lg:mx-40 xl:mx-80 2xl:mx-96 pt-20 relative z-40">
        <div className="grid grid-rows-2 md:flex md:flex-row justify-center">
          <div className="text-justify mt-auto mb-auto order-last md:order-first md:w-7/12 pt-5 min-[767px]:pr-10">
            <LiteratureText>
              <p className="-mt-[40px] sm:-mt-[170px] md:mt-0">
              De acuerdo con{" "}
              <a
                className="underline hover:text-chinred underline-offset-2 font-bold"
                href="https://www.tandfonline.com/doi/full/10.1080/02650487.2019.1575108"
                target="_blank"
                rel="noreferrer"
              >
                Veirman & Hudders (2020)
              </a>{" "}
              los influencers exitosos, y por ende atractivos para la industria
              de comida chatarra y sus campañas de publicidad, tienen tres
              características cruciales: <strong>expertise, autenticidad e intimidad.</strong> La
              intimidad o conexión cercana con sus seguidores es algo que
              distingue a las y los influencers de las celebridades
              tradicionales.
              </p>
            </LiteratureText>
          </div>
          <LiteratureIllustrationContainer>
            <img
            data-aos="fade-left"
            data-aos-duration="1000"
            className="float-right" src={Influencer_Complementaria_2} alt="Chatarra Influencer Illustration" />
          </LiteratureIllustrationContainer>
        </div>
        <div className="sm:flex sm:flex-row justify-center md:pt-10 pt-5">
          <LiteratureIllustrationContainer>
            <img
            data-aos="fade-right"
            data-aos-duration="1000"
            src={Influencer_Complementaria_1} alt="Chatarra Influencer Illustration" />
          </LiteratureIllustrationContainer>
          <div className="text-justify mt-auto mb-auto sm:w-7/12 pt-5 sm:pl-10">
            <LiteratureText>
              En un estudio aplicado a 237 estudiantes,{" "}
              <a
                className="hover:text-chinred underline underline-offset-2 font-bold"
                href="https://www.frontiersin.org/articles/10.3389/fpsyg.2019.03042/full"
                target="_blank"
                rel="noreferrer"
              >
                Boerman (2020)
              </a>{" "}
              encontró que la declaración <strong>“contenido pagado”</strong> es más clara que <strong>“contenido patrocinado”</strong>, y, por ende, el grupo infantil reconoció
              mejor la intención persuasiva del influencer al “invitarles” a
              usar la marca.
            </LiteratureText>
          </div>
        </div>
      </div>
    </LiteratureContainer>
  );
}

export default Literature;
