import styled from "styled-components";

/* Campaign */

export const CampaignContainer = styled.div.attrs({
  className: "font-barlow bg-black snap-y Home h-screen bg-black pb-[76px]",
})`



`;

export const CampaignVideoContainer = styled.div.attrs({
  className: "md:h-screen",
})`

@media screen and (max-width: 767px) {
  height: 95vh;
}

`;
