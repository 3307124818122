import React from "react";

import { ResumenContainer } from "../styles/resumen";
import { MdDownload } from "react-icons/md";
import { ButtonContainer, RedTitle } from "../styles/styles";
import Asset_10 from "../assets/images/Asset 10.svg";
// import ChatarraInfluencer from "../assets/ChatarraInfluencer.pdf";

import Asset_8 from "../assets/images/Asset 8.svg";

import "animate.css";

import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();

function Resumen() {
  return (
    <ResumenContainer>
      <div
        data-aos="zoom-in-left"
        data-aos-duration="1000"
        className="w-[80px] lg:w-[120px] active:w-[70px] active:lg:w-[110px] invisible md:visible absolute right-[20px] lg:right-[60px] 2xl:right-[150px] bottom-0 z-40"
      >
        <img
          className="animate__pulse animate__infinite animate__animated animate__fast"
          src={Asset_8}
          alt="Chatarra Influencer Illustration"
        />
      </div>

      <div className="flex justify-center mx-5 sm:mx-20 lg:mx-40 xl:mx-80 2xl:mx-96">
        <RedTitle>
          ¿POR QUÉ TENER UN OBSERVATORIO CIUDADANO DE INFLUENCERS?
        </RedTitle>
      </div>
      <div className="mx-5 text-lg sm:mx-20 lg:mx-40 xl:mx-80 2xl:mx-96 py-5 md:py-10 text-justify text-chinwhite">
        <p className=" py-3 mb-6  leading-6">
          El presente estudio y observatorio ciudadano evidencian cómo la
          industria de comida chatarra en México crea campañas de publicidad
          dirigidas a niños, niñas y adolescentes (NNA) a través de influencers.
          <br />
          <br />
          El triángulo conformado por empresas, agencias de marketing e
          influencers opera en un contexto casi anárquico, es decir, sin reglas
          y con autoridades ausentes. Esta situación deriva altos riesgos para
          la salud de nuestra infancia y hábitos alimentarios de las futuras
          generaciones. La pandemia del COVID-19 probablemente ha acelerado este
          riesgo. Mientras escuelas y parques permanecieron cerrados, los y las
          NNA consumieron cada vez más contenido digital en celulares, tabletas
          o computadoras.
          <br />
          <br />
          Además del riesgo que representan para la salud pública de esta
          población vulnerable, las campañas de comida chatarra a través de
          influencers incumplen con el derecho de las y los consumidores a tener
          publicidad transparente, identificable, adecuada y veraz. Actualmente
          una gran mayoría de los influencers contratados por la industria de
          comida chatarra vende la confianza de sus seguidores al disfrazar la
          publicidad como “recomendación personal”.
        </p>
        <div className="flex justify-center">
          <a
            href="https://www.tec-check.com.mx/wp-content/uploads/2023/04/Informe-ChatarraInfluencer-Tec-Check-Poder-del-Consumidor.pdf"
            target="_blank"
            rel="noreferrer"
          >
            <ButtonContainer>
              <div>
                <p className="text-sm text-barlow text-end uppercase text-white">
                  Descargar
                </p>
                <p className="text-xl text-barlow text-end font-bold uppercase text-chinred italic">
                  informe
                </p>
              </div>
              <div className="btn btn-circle ml-2 text-[25px]">
                <MdDownload />
              </div>
            </ButtonContainer>
          </a>

          {/* <IntroductionDownloadButton>
          <MdDownload />
          <span className="pl-1">Descargar Informe Completo</span>
        </IntroductionDownloadButton> */}
        </div>
      </div>
    </ResumenContainer>
  );
}

export default Resumen;
