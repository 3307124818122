import React from "react";
import { FaInstagram, FaTwitter } from "react-icons/fa";
import { MdFacebook, MdChevronLeft } from "react-icons/md";

import { NavLogoTitle, NavLogoSubTitle } from "../styles/logo";

import logo_techeck from "../assets/images/logo-techeck.png";
import { Link } from "react-router-dom";

import { HashLink } from "react-router-hash-link";

const Cnavbar = () => {
  return (
    <div className="navbar fixed top-0 bg-chinblack text-white">
      <div className="navbar-start lg:ml-20">
        <HashLink
          to={{
            pathname: "/",
            hash: "#campaigns",
          }}
          className="hover:bg-gray-900/75 bg-black text-white h-full text-center  hover:opacity-75 disabled:opacity-25 disabled:cursor-not-allowed z-10 p-0 m-0 transition-all ease-in-out duration-300 rounded-full"
        >
          <MdChevronLeft className="text-5xl text-white" />
          <span className="sr-only">Prev</span>
        </HashLink>
      </div>
      <div className="navbar-center flex flex-row">
        <Link
          to={{
            pathname: "/",
          }}
        >
          <NavLogoTitle>#CHATARRA </NavLogoTitle>
          <NavLogoSubTitle>INFLUENCER</NavLogoSubTitle>
        </Link>
      </div>
      <div className="navbar-end lg:mr-20">
        <a
          href="https://www.instagram.com/teccheckmx/"
          target="_blank"
          className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 lg:px-3 sm:px-2 px-1 hover:text-chinpink active:text-chinyellow md:text-lg text-[15px]"
          rel="noreferrer"
        >
          <FaInstagram />
        </a>
        <a
          href="https://twitter.com/teccheckmx"
          target="_blank"
          className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 lg:px-3 sm:px-2 px-1 hover:text-chinpink active:text-chinyellow md:text-lg text-[15px]"
          rel="noreferrer"
        >
          <FaTwitter />
        </a>
        <a
          href="https://www.facebook.com/teccheckmx/"
          target="_blank"
          className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 lg:px-3 sm:px-2 px-1 hover:text-chinpink active:text-chinyellow md:text-lg text-[15px]"
          rel="noreferrer"
        >
          <MdFacebook />
        </a>
      </div>
    </div>
  );
};

export default Cnavbar;
