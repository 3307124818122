import React, { useEffect } from "react";

import scrollama from "scrollama";

import Logo from "../components/Logo";

import {
  HeaderContainer,
  HeaderShadowImage,
  HeaderIllustrationContainer,
  HeaderText,
  HeaderHighlightText,
} from "../styles/header";

import logo_techeck from "../assets/images/logo-techeck.png";
import logo_epc from "../assets/images/logoEPC.png";

import ChatarraInfluencer_COVER_IMG from "../assets/images/ChatarraInfluencer_COVER_IMG.png";
import post1 from "../assets/images/post1.png";
import post2 from "../assets/images/post2.png";
import post3 from "../assets/images/post3.png";
import post4 from "../assets/images/post4.png";
import { MdArrowCircleUp, MdArrowDownward, MdArrowForward } from "react-icons/md";
import { IoMdArrowUp } from "react-icons/io";

import AOS from "aos";
import "aos/dist/aos.css";

import { HashLink } from "react-router-hash-link";
import { ButtonContainer } from "../styles/styles";

AOS.init();

const Header = () => {
  // useEffect(() => {
  //   // instantiate the scrollama
  //   const scroller = scrollama();

  //   // setup the instance, pass callback functions
  //   scroller
  //     .setup({
  //       step: ".post",
  //     })
  //     .onStepEnter(response => {
  //       // { element, index, direction }
  //     })
  //     .onStepExit(response => {
  //       // { element, index, direction }
  //     });
  // }, []);

  return (
    <HeaderContainer>
      <div className="hero-content flex flex-col justify-center items-center z-40">
        <div className="grid grid-cols-1 content-start">
          <div
            data-aos="fade-out"
            data-aos-duration="2000"
          >
            <Logo />
          </div>
          <div
            data-aos="zoom-in"
            className="flex justify-center"
          >
            <HeaderIllustrationContainer>
              {/*
              // @ts-ignore */}
              <img
                src={ChatarraInfluencer_COVER_IMG}
                alt="Chatarra Influencer Illustration"
                className="flex justify-center items-center mt-5"
              />
            </HeaderIllustrationContainer>
          </div>
        </div>
        {/* // BUTTON TecCheck */}
        <div
          data-aos="fade-in"
          data-aos-anchor-placement="bottom-bottom"
          className="my-10 flex flex-col justify-center items-center">

          <div className="flex flex-row flex-wrap gap-8 justify-center items-center">
            <a
              href="https://www.tec-check.com.mx/"
              target="_blank"
              className="flex flex-col text-sm mb-7 gap-3 text-center text-white"
              rel="noreferrer"
            >
              <p>Desarrollado por:</p>
              <img className="w-32 mt-1" src={logo_techeck} alt="TeCheck" />
              {/* <div className="flex flex-row align-middle mt-4">
              <button className="btn btn-circle btn-xs ">
                <IoMdArrowUp />
              </button>
            </div> */}
            </a>
            <a
              href="https://www.tec-check.com.mx/"
              target="_blank"
              className=" flex flex-col items-center text-sm mb-7 text-center text-white"
              rel="noreferrer"
            >
              <p>Con el apoyo de:</p>
              <img className="w-32 mt-1" src={logo_epc} alt="TeCheck" />
              {/* <div className="flex flex-row align-middle mt-4">
              <button className="btn btn-circle btn-xs ">
                <IoMdArrowUp />
              </button>
            </div> */}
            </a>
          </div>
          
          <HashLink
            to={{
              pathname: "/",
              hash: "#denuncia",
            }}>
            <ButtonContainer>
              <div>
                <p className="text-xl text-barlow text-end font-bold uppercase text-chinred italic">
                  Denuncia
                </p>
                <p className="text-sm text-barlow text-end uppercase text-white">
                  una campaña
                </p>

              </div>
              
                <MdArrowForward className="btn btn-circle ml-2 text-[25px]"/>
             
            </ButtonContainer>
          </HashLink>
        </div>

        <div
          data-aos="zoom-out-up"
          data-aos-easing="ease-out"
          data-aos-duration="1000"
          className="flex justify-center items-center  my-20 mx-5 lg:mx-20 w-9/12"
        >
          <HeaderText>
            Cómo las corporaciones de la comida chatarra y las bebidas
            endulzadas utilizan a los influencers{" "}
            <HeaderHighlightText>
              para realizar publicidad engañosa y fuera de la ley
            </HeaderHighlightText>{" "}
            para promover el consumo de productos no saludables.
          </HeaderText>
        </div>
        {/* <div className="py-20 px-0">
          <div className="flex flex-row">
            <img className="post w-3/12" src={post1} alt="post1" />
            <img className="post w-3/12" src={post2} alt="post1" />
            <img className="post w-3/12" src={post3} alt="post1" />
            <img className="post w-3/12" src={post4} alt="post1" />
          </div>
          <div className="flex flex-row">
            <img className="post w-3/12" src={post1} alt="post1" />
            <img className="post w-3/12" src={post2} alt="post1" />
            <img className="post w-3/12" src={post3} alt="post1" />
            <img className="post w-3/12" src={post4} alt="post1" />
          </div>
        </div> */}
      </div>
      <HeaderShadowImage />
    </HeaderContainer>
  );
};

export default Header;
