import React from "react";
import "@fontsource/barlow/100.css";
import "@fontsource/barlow/100-italic.css";
import "@fontsource/barlow/200.css";
import "@fontsource/barlow/200-italic.css";
import "@fontsource/barlow/300.css";
import "@fontsource/barlow/300-italic.css";
import "@fontsource/barlow/400.css";
import "@fontsource/barlow/400-italic.css";
import "@fontsource/barlow/500.css";
import "@fontsource/barlow/500-italic.css";
import "@fontsource/barlow/600.css";
import "@fontsource/barlow/600-italic.css";
import "@fontsource/barlow/700.css";
import "@fontsource/barlow/700-italic.css";
import "@fontsource/barlow/800.css";
import "@fontsource/barlow/800-italic.css";
import "@fontsource/barlow/900.css";
import "@fontsource/barlow/900-italic.css";
import "@fontsource/silkscreen/400.css";
import "@fontsource/silkscreen/700.css";
import "@fontsource/bitter";
import "@fontsource/inter";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";

import Home from "./pages/Home";
import Campaign from "./pages/Campaign";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/campana/:slug" element={<Campaign />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
