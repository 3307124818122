import React from "react";

import {
  BttmnavContainer,
  BttmnavMenuAnchor,
  BttmnavMenuIcon,
  BttmnavMenuText,
} from "../styles/bttmnav";

import {
  MdHomeFilled,
  MdEditNote,
  MdPlayCircleFilled,
  MdWavingHand,
  MdList,
  MdFacebook,
  MdDownload,
  MdReport,
} from "react-icons/md";

import AOS from "aos";
import "aos/dist/aos.css";

import { HashLink } from "react-router-hash-link";

AOS.init();

const Bttmnav = () => {
  return (
    <BttmnavContainer>
      <div className="navbar w-full flex justify-around">
        <ul className="menu menu-horizontal flex lg:justify-between -mx-1">
          <li className="flex lg:mx-15 md:mx-10 justify-center items-center">
            <BttmnavMenuAnchor href="#">
              <BttmnavMenuIcon>
                <MdHomeFilled />
              </BttmnavMenuIcon>
              <BttmnavMenuText>Inicio</BttmnavMenuText>
            </BttmnavMenuAnchor>
          </li>
          {/* <li className="flex lg:mx-15 md:mx-10 justify-center items-center">
            <BttmnavMenuAnchor href="#blog">
              <BttmnavMenuIcon>
                <MdEditNote />
              </BttmnavMenuIcon>
              <BttmnavMenuText>Blog</BttmnavMenuText>
            </BttmnavMenuAnchor>
          </li> */}
          <li className="flex lg:mx-15 md:mx-10 justify-center items-center">
            <BttmnavMenuAnchor href="#campaigns">
              <BttmnavMenuIcon>
                <MdPlayCircleFilled />
              </BttmnavMenuIcon>
              <BttmnavMenuText>Campañas</BttmnavMenuText>
            </BttmnavMenuAnchor>
          </li>
          <li className="flex lg:mx-15 md:mx-10 justify-center items-center">
            <HashLink
              to={{
                pathname: "/",
                hash: "#denuncia",
              }}
              className="transition flex flex-col items-center text-center gap-0 justify-around leading-4 ease-in-out delay-150  hover:scale-110 hover:bg-chinblue duration-300 min-[403px]:px-[19px] min-[321px]:px-[15px] px-[10px]"
            >
              
                <BttmnavMenuIcon>
                  <MdReport />
                </BttmnavMenuIcon>
                <BttmnavMenuText>Denuncia</BttmnavMenuText>
              
            </HashLink>
          </li>
          <li className="flex lg:mx-15 md:mx-10 justify-center items-center">
            <BttmnavMenuAnchor href="#nosotros">
              <BttmnavMenuIcon>
                <MdWavingHand />
              </BttmnavMenuIcon>
              <BttmnavMenuText>Nosotros</BttmnavMenuText>
            </BttmnavMenuAnchor>
          </li>
          <li className="flex lg:mx-10 md:mx-5 justify-center items-center">
            <BttmnavMenuAnchor
              href="https://www.tec-check.com.mx/wp-content/uploads/2023/04/Informe-ChatarraInfluencer-Tec-Check-Poder-del-Consumidor.pdf"
              target="_blank"
              rel="noreferrer"
            >
              <BttmnavMenuIcon>
                <MdDownload />
              </BttmnavMenuIcon>
              <BttmnavMenuText>Informe</BttmnavMenuText>
            </BttmnavMenuAnchor>
          </li>
        </ul>
      </div>
    </BttmnavContainer>
  );
};

export default Bttmnav;
