import styled from "styled-components";

/* Introduction */

export const IntroductionContainer = styled.div.attrs({
  id: "introduction",
  className: "bg-black pt-20",
})``;

export const IntroductionText = styled.p.attrs({
  className: " text-white text-lg text-justify flex items-center",
})``;

export const IntroductionIllustrationContainer = styled.div.attrs({
  className: "sm:w-4/12 md:w-4/12 w-7/12 block ml-auto mr-auto",
})``;

export const IntroductionSeeMoreButton = styled.button.attrs({
  className: "btn btn-link text-lg text-neutral-700 normal-case p-0",
})``;

export const IntroductionDownloadButton = styled.button.attrs({
  className:
    "btn text-md text-chinyellow bg- normal-case px-4 h-10 min-h-0 rounded-none font-semibold",
})``;
