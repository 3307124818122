import React from "react";

import Header from "../views/Header";
import Introduction from "../views/Introduction";
import Blog from "../views/Blog";
import Influencers from "../views/Influencers";
import Literature from "../views/Literature";
import Regulacion from "../views/Regulacion";
import Campaigns from "../views/Campaigns";
import Conclusions from "../views/Conclusions";
import Nosotros from "../views/Nosotros";
import Footer from "../views/Footer";
import Resumen from "../views/Resumen";
import Reports from "../views/Reports";
import Influencerpeor from "../views/Influencerpeor";
import Alemania from "../views/Alemania";
import BlogPost from "../views/BlogPost";

import Navbar from "../views/Navbar";
import Bttmnav from "../views/Bttmnav";

import { HomeContainer } from "../styles/home";

import {
  InfluencersLiteratureContainer,
  InfluencersLiteratureShadowImage,
  RegulationCampaignsConclusionsContainer,
  RegulationCampaignsConclusionsShadowImage,
} from "../styles/styles";
import Recent from "../views/Recent";
import 'animate.css';

function Home() {
  return (
    <div className="animate__fadeIn animate__animated animate__slower">
      <Navbar />
      <Bttmnav />
      <HomeContainer >
        <Header />
        <Recent />
        <Reports />
        <RegulationCampaignsConclusionsContainer>
          <RegulationCampaignsConclusionsShadowImage />
          <Campaigns />
          <Resumen />
        </RegulationCampaignsConclusionsContainer>

        <Introduction />
        {/* <Blog /> */}
        <InfluencersLiteratureContainer>
          <InfluencersLiteratureShadowImage />
          <Influencers />
          <Literature />
        </InfluencersLiteratureContainer>
        <RegulationCampaignsConclusionsContainer>
          <RegulationCampaignsConclusionsShadowImage />
          <Regulacion />
          <Conclusions />
        </RegulationCampaignsConclusionsContainer>
        {/* <InfluencersLiteratureContainer>
          <InfluencersLiteratureShadowImage /> */}

        {/* </InfluencersLiteratureContainer> */}
        <Nosotros />
        {/* <InfluencersLiteratureContainer>
        <InfluencersLiteratureShadowImage />
        <Influencerpeor/>
        </InfluencersLiteratureContainer> */}
        <Footer />
      </HomeContainer>
    </div>
  );
}

export default Home;
