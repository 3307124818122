import React from "react";

import { FaInstagram, FaTwitter, FaLinkedinIn } from "react-icons/fa";
import { MdArrowForward, MdFacebook } from "react-icons/md";

import MailchimpSubscribe from "react-mailchimp-subscribe";

import {
  FooterContainer,
  FooterGrid,
  FooterTitle,
  FooterDescription,
  FooterLink,
  FooterCopyright,
} from "../styles/footer";

import logo_techeck from "../assets/images/logo-techeck.png";
import logoEPC from "../assets/images/logoEPC.png";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const url =
  "https://us15.list-manage.com/subscribe/post?u=8abc764ab9dd3b7bdfc4acd86&id=ad7799b919";

const Footer = () => {
  const notify = () =>
    toast(
      "Gracias por tu interés en conocer más sobre el trabajo de Tec-Check A.C. Por favor, confirma tu suscripción a nuestro newsletter en tu correo electrónico."
    );

  // @ts-ignore
  const onSubmit = e => {
    e.preventDefault();
    // console.log("refresh prevented");
  };

  return (
    <FooterContainer>
      <ToastContainer className="z-50" />
      <FooterGrid>
        {/* <div>
        <FooterTitle>About Us</FooterTitle>
        <FooterDescription>
          Melody Nest is a music industry freelance marketplace that connects
          audio engineers, vocalists, graphic designers, session musicians,
          music producers and more to ambitious musicians, producers, and DJs
          looking to build their career
        </FooterDescription>
      </div>
      <div className="pl-20 ml-20">
        <FooterTitle>Content</FooterTitle>
        <FooterLink>Partnerships</FooterLink>
        <FooterLink>Become a Seller</FooterLink>
        <FooterLink>FAQ</FooterLink>
      </div>
      <div className="ml-10">
        <FooterTitle>Legal Information</FooterTitle>
        <FooterLink>Terms & Conditions</FooterLink>
        <FooterLink>License Agreement</FooterLink>
        <FooterLink>Privacy policy</FooterLink>
        <FooterLink>Contact</FooterLink>
      </div>
      <div className="ml-20">
        <FooterTitle>Follow Us</FooterTitle>
        <div className="flex justify-center">
          <div className="grid grid-cols-3 gap-4">
            <a>
              <FaInstagram size="20px" />
            </a>
            <a>
              <FaTwitter size="20px" />
            </a>
            <a>
              <FaLinkedinIn size="20px" />
            </a>
          </div>
        </div>
      </div> */}

        {/* TECHECK */}

        <div className="m-1">
          <p className="text-lg mb-7 text-center">Página desarrollada por:</p>
          <a
            href="https://www.tec-check.com.mx/"
            className="hover:scale-110"
            target="_blank"
            rel="noreferrer"
          >
            <img className="w-60" src={logo_techeck} alt="TeCheck" />
          </a>
          <div className="flex pt-8 justify-center">
            <a
              href="https://www.instagram.com/teccheckmx/"
              target="_blank"
              className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 lg:px-3 sm:px-2 px-1 hover:text-chinpink active:text-chinyellow md:text-lg text-[15px]"
              rel="noreferrer"
            >
              <FaInstagram />
            </a>
            <a
              href="https://twitter.com/teccheckmx"
              target="_blank"
              className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 lg:px-3 sm:px-2 px-1 hover:text-chinpink active:text-chinyellow md:text-lg text-[15px]"
              rel="noreferrer"
            >
              <FaTwitter />
            </a>
            <a
              href="https://www.facebook.com/teccheckmx/"
              target="_blank"
              className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 lg:px-3 sm:px-2 px-1 hover:text-chinpink active:text-chinyellow md:text-lg text-[15px]"
              rel="noreferrer"
            >
              <MdFacebook />
            </a>
          </div>
        </div>

        {/* EPC */}

        <div className="m-1">
          <p className="text-lg mb-3 text-center">Con el apoyo de:</p>
          <a
            href="https://elpoderdelconsumidor.org/"
            className="hover:scale-110"
            target="_blank"
            rel="noreferrer"
          >
            <img className="w-56" src={logoEPC} alt="El Poder del Consumidor" />
          </a>
          <div className="flex pt-6 justify-center">
            <a
              href="https://www.instagram.com/poderdelc/"
              target="_blank"
              className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 lg:px-3 sm:px-2 px-1 hover:text-chinpink active:text-chinyellow md:text-lg text-[15px]"
              rel="noreferrer"
            >
              <FaInstagram />
            </a>
            <a
              href="https://twitter.com/elpoderdelc"
              target="_blank"
              className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 lg:px-3 sm:px-2 px-1 hover:text-chinpink active:text-chinyellow md:text-lg text-[15px]"
              rel="noreferrer"
            >
              <FaTwitter />
            </a>
            <a
              href="https://www.facebook.com/elpoderdelc"
              target="_blank"
              className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 lg:px-3 sm:px-2 px-1 hover:text-chinpink active:text-chinyellow md:text-lg text-[15px]"
              rel="noreferrer"
            >
              <MdFacebook />
            </a>
          </div>
        </div>

        {/* NEWSLETTER */}

        <MailchimpSubscribe
          url={url}
          render={({ subscribe, status, message }) => (
            <div className="md:col-span-2 flex justify-center lg:text-start text-center lg:mt-1 mr-1 ml-1 mt-12">
              {/*
              // @ts-ignore */}
              <form
                onSubmit={onSubmit}
                /*
                  // @ts-ignore */
                onSubmitted={formData => subscribe(formData)}
              >
                <div className="flex justify-center lg:justify-start mb-3">
                  <h1 className="text-black bg-white px-2 py-1 text-xl font-bold uppercase italic">
                    Boletín
                  </h1>
                </div>
                <p className="text-lg">
                  Suscríbete al newsletter de Tec-Check.
                </p>
                <input
                  type="email"
                  placeholder="Email"
                  className="text-black input input-bordered w-full mt-3"
                />
                <div className="form-control">
                  <label className="mt-3 items-center cursor-pointer">
                    <input
                      type="checkbox"
                      className="checkbox bg-black border-white h-[1.25rem] w-[1.25rem]"
                    />
                    <span className="label-text text-white ml-3">
                      He leído y acepto el{" "}
                      <a
                        href="https://www.tec-check.com.mx/aviso-de-privacidad-tec-check/"
                        className="underline"
                        target="_blank"
                        rel="noreferrer"
                      >
                        aviso de privacidad
                      </a>{" "}
                      por parte de Tec-Check
                    </span>
                  </label>
                  <button type="submit" onClick={notify} className="btn mt-3">
                    Enviar
                    <MdArrowForward />
                  </button>
                </div>
              </form>
              {status === "sending" && (
                <div style={{ color: "blue" }}>Enviando...</div>
              )}
              {status === "error" && (
                <div
                  style={{ color: "red" }}
                  /*
                  // @ts-ignore */
                  dangerouslySetInnerHTML={{ __html: message }}
                />
              )}
              {status === "success" && (
                <div style={{ color: "green" }}>¡Suscrito!</div>
              )}
            </div>
          )}
        />
      </FooterGrid>

      <FooterCopyright>
        <p>
        © 2022 Tec-Check. Todos los Derechos Reservados |  {" "}
          <a
            href="https://www.tec-check.com.mx/aviso-de-privacidad-tec-check/"
            className="underline"
            target="_blank"
            rel="noreferrer"
          >
            Aviso de Privacidad 
          </a>{" "}
          |{" "}
        </p>
      </FooterCopyright>
    </FooterContainer>
  );
};

export default Footer;
