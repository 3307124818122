import styled from "styled-components";

/* Bttmnav */

export const BttmnavContainer = styled.div.attrs({
  className: "navbar fixed bottom-0 h-8 z-50 px-0",
})`
  /* ff 3.6+ */
  background: -moz-linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    #080808 60%,
    #101010 100%
  );

  /* safari 5.1+,chrome 10+ */
  background: -webkit-linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    #080808 60%,
    #101010 100%
  );

  /* opera 11.10+ */
  background: -o-linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    #080808 60%,
    #101010 100%
  );

  /* ie 6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=1 );

  /* ie 10+ */
  background: -ms-linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    #080808 60%,
    #101010 100%
  );

  /* global 94%+ browsers support */
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    #080808 60%,
    #101010 100%
  );
  font-family: "Barlow";
`;

export const BttmnavMenuAnchor = styled.a.attrs({
  className: "transition ease-in-out delay-150  hover:scale-110 hover:bg-chinblue duration-300 min-[403px]:px-[19px] min-[321px]:px-[15px] px-[10px]",
})`
  font-family: "Barlow";
  font-style: normal;
  font-weight: 200;
  line-height: 16px;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  gap: 0;

  color: white;

  :active {
    background-color: transparent;
    color: white;
  }
`;

export const BttmnavMenuIcon = styled.div.attrs({
  className: "text-white hover:text-chinyellow active:text-chinpink active:text-chinpink visited:text-chinyellow",
})`
  font-family: "Barlow";
  font-style: normal;
  font-weight: 200;
  font-size: 20px;
`;

export const BttmnavMenuText = styled.p`
  font-family: "Barlow";
  font-style: normal;
  font-weight: 200;
  font-size: 10px;

  color: white;
`;

