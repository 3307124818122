import styled, { keyframes } from "styled-components";
import { pulse } from "react-animations";

const pulseAnimation = keyframes`${pulse}`;

export const YellowTitle = styled.span.attrs({
  className:
    "px-6 py-4 text-center uppercase font-bold bg-chinyellow flex flex-col items-center text-3xl text-chinblack italic scale-75 md:scale-90",
})`
    width: max-content;
`;

export const PinkTitle = styled.span.attrs({
  className:
    "px-6 py-4 uppercase font-bold bg-chinpink flex flex-col items-center text-3xl text-chinblack italic scale-75 md:scale-90",
})`
    width: max-content;
`;

export const RedTitle = styled.span.attrs({
  className:
    "px-6 py-4 text-center uppercase font-bold bg-chinred flex flex-col items-center text-3xl text-white italic scale-75 md:scale-90",
})`
    width: max-content;
`;

export const BlueTitle = styled.span.attrs({
  className:
    "px-6 py-4 mb-10 mt-5 scale-75 md:scale-90 uppercase text-center font-bold bg-chinblue flex flex-col items-center text-3xl text-chinwhite italic",
})``;

export const InfluencersLiteratureContainer = styled.div.attrs({
  className: "h-full relative z-10",
})`
   {
    /* ff 3.6+ */
    background: -moz-radial-gradient(
      circle at 98% 19%,
      rgba(231, 247, 197, 1) 0%,
      rgba(87, 86, 213, 1) 50%,
      rgba(231, 247, 197, 1) 99%
    );

    /* safari 5.1+,chrome 10+ */
    background: -webkit-radial-gradient(
      circle at 98% 19%,
      rgba(231, 247, 197, 1) 0%,
      rgba(87, 86, 213, 1) 50%,
      rgba(231, 247, 197, 1) 99%
    );

    /* opera 11.10+ */
    background: -o-radial-gradient(
      circle at 98% 19%,
      rgba(231, 247, 197, 1) 0%,
      rgba(87, 86, 213, 1) 50%,
      rgba(231, 247, 197, 1) 99%
    );

    /* ie 10+ */
    background: -ms-radial-gradient(
      circle at 98% 19%,
      rgba(231, 247, 197, 1) 0%,
      rgba(87, 86, 213, 1) 50%,
      rgba(231, 247, 197, 1) 99%
    );

    /* global 92%+ browsers support */
    background: radial-gradient(
      circle at 98% 19%,
      rgba(231, 247, 197, 1) 0%,
      rgba(87, 86, 213, 1) 50%,
      rgba(231, 247, 197, 1) 99%
    );
  }
`;

export const InfluencersLiteratureShadowImage = styled.div.attrs({
  className: "absolute top-0 bottom-0 left-0 right-0 z-30",
})`
   {
    /* ff 3.6+ */
    background: -moz-linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 1) 20%,
      rgba(0, 0, 0, 0.4) 30%,
      rgba(255, 255, 255, 1) 45%,
      rgba(255, 255, 255, 1) 54%,
      rgba(0, 0, 0, 0.4) 75%,
      rgba(0, 0, 0, 1) 100%
    );

    /* safari 5.1+,chrome 10+ */
    background: -webkit-linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 1) 20%,
      rgba(0, 0, 0, 0.4) 30%,
      rgba(255, 255, 255, 1) 45%,
      rgba(255, 255, 255, 1) 54%,
      rgba(0, 0, 0, 0.4) 75%,
      rgba(0, 0, 0, 1) 100%
    );

    /* opera 11.10+ */
    background: -o-linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 1) 20%,
      rgba(0, 0, 0, 0.4) 30%,
      rgba(255, 255, 255, 1) 45%,
      rgba(255, 255, 255, 1) 54%,
      rgba(0, 0, 0, 0.4) 75%,
      rgba(0, 0, 0, 1) 100%
    );

    /* ie 6-9 */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=1 );

    /* ie 10+ */
    background: -ms-linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 1) 20%,
      rgba(0, 0, 0, 0.4) 30%,
      rgba(255, 255, 255, 1) 45%,
      rgba(255, 255, 255, 1) 54%,
      rgba(0, 0, 0, 0.4) 75%,
      rgba(0, 0, 0, 1) 100%
    );

    /* global 94%+ browsers support */
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 1) 20%,
      rgba(0, 0, 0, 0.4) 30%,
      rgba(255, 255, 255, 1) 45%,
      rgba(255, 255, 255, 1) 54%,
      rgba(0, 0, 0, 0.4) 75%,
      rgba(0, 0, 0, 1) 100%
    );
  }
`;

export const RegulationCampaignsConclusionsContainer = styled.div.attrs({
  className: "h-full relative",
})`
   {
    /* ff 3.6+ */
    background: -moz-radial-gradient(
      circle at 70% 60%,
      rgba(233, 245, 197, 1) 0%,
      rgba(233, 245, 197, 1) 20%,
      rgba(240, 181, 214, 1) 51%,
      rgba(240, 181, 214, 1) 99%
    );

    /* safari 5.1+,chrome 10+ */
    background: -webkit-radial-gradient(
      circle at 70% 60%,
      rgba(233, 245, 197, 1) 0%,
      rgba(233, 245, 197, 1) 20%,
      rgba(240, 181, 214, 1) 51%,
      rgba(240, 181, 214, 1) 99%
    );

    /* opera 11.10+ */
    background: -o-radial-gradient(
      circle at 70% 60%,
      rgba(233, 245, 197, 1) 0%,
      rgba(233, 245, 197, 1) 20%,
      rgba(240, 181, 214, 1) 51%,
      rgba(240, 181, 214, 1) 99%
    );

    /* ie 10+ */
    background: -ms-radial-gradient(
      circle at 70% 60%,
      rgba(233, 245, 197, 1) 0%,
      rgba(233, 245, 197, 1) 20%,
      rgba(240, 181, 214, 1) 51%,
      rgba(240, 181, 214, 1) 99%
    );

    /* global 92%+ browsers support */
    background: radial-gradient(
      circle at 70% 60%,
      rgba(233, 245, 197, 1) 0%,
      rgba(233, 245, 197, 1) 20%,
      rgba(240, 181, 214, 1) 51%,
      rgba(240, 181, 214, 1) 99%
    );
  }
`;

export const RegulationCampaignsConclusionsShadowImage = styled.div.attrs({
  className: "absolute top-0 bottom-0 left-0 right-0 z-30",
})`
   {
    /* ff 3.6+ */
    background: -moz-linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 1) 20%,
      rgba(0, 0, 0, 0.4) 40%,
      rgba(0, 0, 0, 0.4) 70%,
      rgba(0, 0, 0, 1) 85%,
      rgba(0, 0, 0, 1) 100%
    );

    /* safari 5.1+,chrome 10+ */
    background: -webkit-linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 1) 20%,
      rgba(0, 0, 0, 0.4) 40%,
      rgba(0, 0, 0, 0.4) 70%,
      rgba(0, 0, 0, 1) 85%,
      rgba(0, 0, 0, 1) 100%
    );

    /* opera 11.10+ */
    background: -o-linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 1) 20%,
      rgba(0, 0, 0, 0.4) 40%,
      rgba(0, 0, 0, 0.4) 70%,
      rgba(0, 0, 0, 1) 85%,
      rgba(0, 0, 0, 1) 100%
    );

    /* ie 6-9 */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=1 );

    /* ie 10+ */
    background: -ms-linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 1) 20%,
      rgba(0, 0, 0, 0.4) 40%,
      rgba(0, 0, 0, 0.4) 70%,
      rgba(0, 0, 0, 1) 85%,
      rgba(0, 0, 0, 1) 100%
    );

    /* global 94%+ browsers support */
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 1) 20%,
      rgba(0, 0, 0, 0.4) 40%,
      rgba(0, 0, 0, 0.4) 70%,
      rgba(0, 0, 0, 1) 85%,
      rgba(0, 0, 0, 1) 100%
    );
  }
`;



//////////////// PAISES ////////////

export const CountriesContainer = styled.div.attrs({
  className:
    "",
})`

`;

//////////////// BOTON ////////////

export const ButtonContainer = styled.button.attrs({
  className: "transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 bg-black hover:bg-zinc-800 flex p-2 mt-4",
})`

`;

export const ButtonCircle = styled.div.attrs({
  className: "btn btn-circle mx-2 text-[35px]",
})`

`;

export const ButtonSmText = styled.p.attrs({
  className: "text-sm text-barlow uppercase text-white",
})`

`;

export const ButtonLgText = styled.p.attrs({
  className: "text-lg text-barlow font-bold uppercase text-chinred italic",
})`

`;

