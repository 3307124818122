import styled from "styled-components";

/* Header Logo */

export const LogoContainer = styled.div.attrs({
  className: "flex flex-col items-center mt-10",
})`

`;

export const LogoTopTitle = styled.span.attrs({
  className: "px-4 py-4 bg-chinblack text-4xl text-white tracking-widest",
})`
  font-family: Silkscreen, sans-serif !important;
  width: max-content;

  @media(max-height:813px) {
    font-size: 25px;

  }

`;

export const LogoSubTitle = styled.span.attrs({
  className: "px-4 -m-2 py-2 bg-chinblue text-4xl text-white italic",
})`
  width: max-content;
  @media(max-height:813px) {
    font-size: 25px;

  }
`;

export const LogoThirdTitle = styled.span.attrs({
  className: "py-1 pr-2 pl-3 mt-5 tracking-[0.50rem] bg-chinblack text-md text-white tracking-widest",
})`

  width: max-content;
  @media(max-height:813px) {
    font-size: 10px;

  }

`;

/* Navbar Logo */

export const NavLogoTitle = styled.span.attrs({
  className: "-mr-1 -ml-4 md:ml-0 md:text-sm text-[12px] text-white tracking-widest",
})`
  font-family: Silkscreen, sans-serif !important;
  width: max-content;
`;

export const NavLogoSubTitle = styled.span.attrs({
  className: "md:px-3 px-1.5  py-1 bg-chinblue md:text-sm text-[12px] text-white italic",
})`
  width: max-content;
  `;
