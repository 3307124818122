import styled from "styled-components";

/* Campaigns */
export const CampaignsFullContainer = styled.div.attrs({
  id: "campaigns", className: "py-20 flex flex-col items-center relative z-40",
})``;

export const CampaignsContainer = styled.div.attrs({
  className:
    "flex grid justify-center  grid-cols-2 mt-10 xl:grid-cols-4",
})``;

export const CampaignsCard = styled.div.attrs({
  className:
    "rounded-none my-5 mx-3 card w-40 sm:w-72 lg:w-64 xl:w-52 hover:scale-105 ease-in-out duration-300 bg-chinblack shadow-xl image-full",
})``;

export const CampaignsBody = styled.div.attrs({
  className: "rounded-none m-auto items-center text-center card-body",
})``;

export const CampaignsBeforeTitle = styled.h1.attrs({
  className:
    "card-title md:text-lg text-sm text-center uppercase font-normal text-chinred",
})``;

export const CampaignsTitle = styled.h1.attrs({
  className:
    "card-title md:text-2xl text-lg text-center italic uppercase font-bold text-chinwhite",
})``;
