import React from "react";

import { LogoContainer, LogoTopTitle, LogoSubTitle, LogoThirdTitle } from "../styles/logo";

const Logo = () => {
  return (
    <LogoContainer>
      <LogoTopTitle>#CHATARRA</LogoTopTitle>
      <LogoSubTitle>INFLUENCER</LogoSubTitle>
      <LogoThirdTitle>OBSERVATORIO</LogoThirdTitle>
    </LogoContainer>
  );
};

export default Logo;
