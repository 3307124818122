import styled from "styled-components";
import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();

export const ReportsContainer = styled.div.attrs({
  id: "denuncia",
  className: "pt-20 z-30 relative",
})``;

export const ReportsCard = styled.div.attrs({
  className:
    "flex flex-col drop-shadow-lg p-5 md:p-11 my-auto mx-5 sm:mx-30 min-[600px]:mx-[100px] min-[1000px]:mx-[250px] min-[1150]:mx-[350px] xl:mx-[400px] 2xl:mx-[500px] text-white bg-zinc-900",
})``;

export const ReportsSubtitle = styled.p.attrs({
  className: "text-center py-3 text-xl",
})``;
