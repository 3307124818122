import React from "react";

import { ConclusionsContainer } from "../styles/conclusions";
import { RedTitle } from "../styles/styles";

function Conclusions() {
  return (
    <ConclusionsContainer>
      <div className="flex justify-center">
        <RedTitle>¿QUÉ SE DEBE HACER?</RedTitle>
      </div>
      <div className="mx-5 text-lg sm:mx-20 lg:mx-40 xl:mx-80 2xl:mx-96 py-5 md:py-10 text-justify text-chinwhite">
        <p className=" py-3 mb-6  leading-6">
          Con base en el observatorio concluimos que la autorregulación en México no ha protegido a las y los consumidores de campañas de publicidad engañosa para comida chatarra.
          <br /><br />Como parte de la investigación, muchas personas nos dijeron que es la responsabilidad de los padres y las madres proteger a sus hijos e hijas de la publicidad engañosa y que limitar a los influencers sería un ataque a la libertad de expresión en México. Este tipo de argumentos para los autores del estudio son un reflejo de un entendimiento de libertad de expresión equivocado. Por supuesto padres y madres deberían cuidar a sus hijos(as), ellos no pueden cambiar ni mucho menos impedir la publicidad que sus hijos ven diariamente. Nuestro derecho a libertad de expresión no engloba mentiras, fraude y más prácticas engañosas para generar beneficios económicos propios. Y aunque niños y niñas aprenden en casa o en la escuela que el agua natural es más saludable que una Coca Cola, si sus ídolos e influencers siguen promocionado la marca, es muy probablemente que las niñas y los niños preferirán una Coca por encima del agua natural.
          <br /><br />Proponemos las siguientes medidas para una política pública que regule la publicidad engañosa a través de influencers para comida chatarra.
        </p>
        <p className="md:columns-2 md:gap-12">
          <span className="bg-chinred text-xl pb-1 px-1 italic">1. Observatorio ciudadano de influencers:</span> crear en cooperación con la PROFECO y/o COFEPRIS y el sector privado un observatorio desde la sociedad civil que rastree publicidad a través influencers, especialmente para productos que atentan contra la salud pública como comida chatarra, bebidas alcohólicas y suplementos alimenticios no autorizados.
          <br /><span className="bg-chinred text-xl pb-1 px-1 italic">2. Precedentes de multas por publicidad engañosa:</span> Hay que sancionar prácticas de publicidad engañosa por parte de influencers, empresas y agencias de marketing.
          <br /><span className="bg-chinred text-xl pb-1 px-1 italic">3. Campaña de concientización y educación sobre publicidad digital:</span> muchos seguidores no conocen diferentes esquemas de publicidad engañosa, por ejemplos, el uso de links afiliados, give-aways, sorteos, etc. Proponemos iniciar una campaña para sensibilizar a las y los NNA sobre dichas prácticas.
          <br /><span className="bg-chinred text-xl pb-1 px-1 italic">4. Proteger los derechos de NNA influencers:</span> Es urgente emitir una postura de las autoridades sobre la afectación a los derechos de NNA que son influencers. Hay que regular los acuerdos comerciales entre las marcas y tutores de influencers infantiles.
          <br /><span className="bg-chinred text-xl pb-1 px-1 italic">5. Restringir la publicidad para comida chatarra en redes sociales:</span> Solicitamos la restricción masiva de publicidad de comida chatarra en redes sociales dirigida a NNA. De esta forma México cumpliría la recomendación de la Organización Mundial de Salud.
        </p>

      </div>
    </ConclusionsContainer>
  );
}

export default Conclusions;
