import React, { useEffect } from "react";
import { MdArrowForward } from "react-icons/md";
import {
  ReportsCard,
  ReportsContainer,
  ReportsSubtitle,
} from "../styles/reports";
import { BlueTitle } from "../styles/styles";

import AOS from "aos";
import "aos/dist/aos.css";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";

import ReCAPTCHA from "react-google-recaptcha";

import { useForm, SubmitHandler, Controller } from "react-hook-form";

AOS.init();

type Inputs = {
  comments: String;
  influencer_name: String;
  username: String;
  campaign_name: String;
  publication_date: String;
  file_url: String;
  files: FileList;
  evidence_url: String;
  email: string;
  acceptPrivacyPolicy: boolean;
};

function Reports() {
  const notify = () => toast("Gracias por compartirnos la información.");

  const {
    register,
    handleSubmit,
    watch,
    control,
    reset,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      comments: "",
      influencer_name: "",
      username: "",
      campaign_name: "",
      publication_date: "",
      file_url: "",
      files: undefined,
      evidence_url: "",
      email: "",
      acceptPrivacyPolicy: false,
    },
  });
  const onSubmit: SubmitHandler<Inputs> = async data => {
    //console.log({ data });

    const formData = new FormData();
    formData.append("file", data.files[0]);

    try {
      const response = await axios.post(
        //"http://localhost:1337/api/media",
        "https://chatarrainfluencer.mx/strapi/api/media",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      //console.log(response.data.data.Location);

      data.file_url = response.data.data.Location;

      axios
        //.post("http://localhost:1337/api/reports", { data })
        .post("https://chatarrainfluencer.mx/strapi/api/reports", { data })
        .then(response => {
          //console.log(response);
          reset();
          notify();
        })
        .catch(err => {
          //console.log(err);
        });
    } catch (err) {
      //console.log(err);
    }
    // .then(res => {
    //   console.log(res);
    // })
    // .catch(err => {
    //   console.log(err);
    // });
  };

  //console.log(watch());

  // @ts-ignore
  const onChange = value => {
    //console.log("Captcha value:", value);
  };

  useEffect(() => {
    const subscription = watch((value, { name, type }) =>
      //console.log(value, name, type)
      {}
    );
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <ReportsContainer>
      <ToastContainer className="z-50" />
      <div data-aos="zoom-in" data-aos-duration="1000">
        <ReportsCard>
          <div className="flex flex-col items-center">
            <BlueTitle>Denuncia una campaña #ChatarraInfluencer</BlueTitle>
          </div>
          <ReportsSubtitle>
            Si has identificado una campaña sobre productos no saludables por
            parte de algún influencer, compártenos la evidencia para incluirla
            en el observatorio.
          </ReportsSubtitle>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="block lg:flex lg:flex-row w-full">
              <label className="w-1/2">
                <span>Nombre de influencer:</span>
                <input
                  type="text"
                  placeholder="Escribe aquí"
                  className="input w-full text-black my-2"
                  {...register("influencer_name")}
                />
              </label>
              <label className="lg:ml-3 w-1/2">
                <span>Nombre de su cuenta:</span>
                <input
                  type="text"
                  placeholder="Inician con @"
                  className="input w-full text-black my-2"
                  {...register("username")}
                />
              </label>
            </div>
            <div className="block lg:flex lg:flex-row w-full">
              <label>
                <span>Nombre de campaña/empresa/marca:</span>
                <input
                  type="text"
                  placeholder="Escribe aquí"
                  className="input w-full text-black my-2"
                  {...register("campaign_name")}
                />
              </label>
              <label className="ml-3">
                <span>Fecha en la que tomaste la evidencia:</span>
                <input
                  type="date"
                  className="input w-full text-black my-2"
                  {...register("publication_date")}
                />
              </label>
            </div>
            <label className="block my-2">
              <span>Imagen o video de la evidencia:</span>
              <br/><span className="text-gray-400 leading-none text-sm"> Si se trata de una historia en tipo video o reel, nos ayuda mucho que grabes la historia completa y compartas ese video. Gracias.</span>
              <input
                type="file"
                className="block w-full text-sm text-white
                        file:mr-4 file:py-2 file:px-4
                        file:rounded-full file:border-0
                        file:text-sm file:font-semibold
                        file:bg-chinyellow file:text-black
                        hover:file:bg-chinblue hover:file:text-white my-2"
                {...register("files")}
              />
            </label>
            <label>
              <label>
                <span>Enlace de la evidencia:</span>
                <input
                  type="text"
                  placeholder="Escribe aquí"
                  className="input w-full text-black my-2"
                  {...register("evidence_url")}
                />
              </label>
              <span>Comentarios:</span>
              <textarea
                placeholder="Escribe aquí"
                className="input textarea textarea-lg h-[100px] w-full text-black my-2"
                {...register("comments")}
              ></textarea>
            </label>

            <div className="w-full flex justify-center">
              <label className="w-full">
                <span>Correo de contacto:</span>
                <input
                  type="text"
                  placeholder="Escribe aquí"
                  className="input w-full text-black my-2"
                  {...register("email")}
                />
              </label>
            </div>

            <div className="w-full flex justify-center">
              <label className="w-full flex flex-row items-center">
                <input
                  type="checkbox"
                  className="input  text-black mr-2"
                  {...register("acceptPrivacyPolicy")}
                />
                <span>
                  He leído y estoy de acuerdo con el{" "}
                  <a
                    href="https://www.tec-check.com.mx/aviso-de-privacidad-tec-check/"
                    target="_target"
                    className="underline"
                  >
                    aviso de privacidad de Tec-Check A.C.
                  </a>
                </span>
              </label>
            </div>

            <div className="flex justify-center mt-7 mb-1">
              <ReCAPTCHA
                // @ts-ignore
                sitekey={process.env.REACT_APP_SITE_KEY}
                onChange={onChange}
              />
            </div>

            <button type="submit" className="btn w-full mt-5">
              Enviar
              <MdArrowForward />
            </button>
          </form>
        </ReportsCard>
      </div>
    </ReportsContainer>
  );
}

export default Reports;
