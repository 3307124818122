import styled, { keyframes } from "styled-components";
import { pulse } from "react-animations";

const pulseAnimation = keyframes`${pulse}`;

/* Header */

export const HeaderContainer = styled.div.attrs({
  className: "hero bg-base-200 relative font-barlow",
})`
   {
    /* ff 3.6+ */
    background: -moz-radial-gradient(
      circle at 70% 40%,
      rgba(233, 245, 197, 1) 0%,
      rgba(233, 245, 197, 1) 30%,
      rgba(240, 181, 214, 1) 49%,
      rgba(240, 181, 214, 1) 100%
    );

    /* safari 5.1+,chrome 10+ */
    background: -webkit-radial-gradient(
      circle at 70% 40%,
      rgba(233, 245, 197, 1) 0%,
      rgba(233, 245, 197, 1) 30%,
      rgba(240, 181, 214, 1) 49%,
      rgba(240, 181, 214, 1) 100%
    );

    /* opera 11.10+ */
    background: -o-radial-gradient(
      circle at 70% 40%,
      rgba(233, 245, 197, 1) 0%,
      rgba(233, 245, 197, 1) 30%,
      rgba(240, 181, 214, 1) 49%,
      rgba(240, 181, 214, 1) 100%
    );

    /* ie 10+ */
    background: -ms-radial-gradient(
      circle at 70% 40%,
      rgba(233, 245, 197, 1) 0%,
      rgba(233, 245, 197, 1) 30%,
      rgba(240, 181, 214, 1) 49%,
      rgba(240, 181, 214, 1) 100%
    );

    /* global 92%+ browsers support */
    background: radial-gradient(
      circle at 70% 40%,
      rgba(233, 245, 197, 1) 0%,
      rgba(233, 245, 197, 1) 30%,
      rgba(240, 181, 214, 1) 49%,
      rgba(240, 181, 214, 1) 100%
    );
  }
`;

export const HeaderShadowImage = styled.div.attrs({
  className: "absolute top-0 bottom-0 left-0 right-0 z-30",
})`
   {
    /* ff 3.6+ */
    background: -moz-linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 1) 40%,
      rgba(0, 0, 0, 0.57) 55%,
      rgba(0, 0, 0, 0.2) 65%,
      rgba(0, 0, 0, 0) 89%,
      rgba(0, 0, 0, 0) 100%
    );

    /* safari 5.1+,chrome 10+ */
    background: -webkit-linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 1) 40%,
      rgba(0, 0, 0, 0.57) 55%,
      rgba(0, 0, 0, 0.2) 65%,
      rgba(0, 0, 0, 0) 89%,
      rgba(0, 0, 0, 0) 100%
    );

    /* opera 11.10+ */
    background: -o-linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 1) 40%,
      rgba(0, 0, 0, 0.57) 55%,
      rgba(0, 0, 0, 0.2) 65%,
      rgba(0, 0, 0, 0) 89%,
      rgba(0, 0, 0, 0) 100%
    );

    /* ie 6-9 */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=1 );

    /* ie 10+ */
    background: -ms-linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 1) 40%,
      rgba(0, 0, 0, 0.57) 55%,
      rgba(0, 0, 0, 0.2) 65%,
      rgba(0, 0, 0, 0) 89%,
      rgba(0, 0, 0, 0) 100%
    );

    /* global 94%+ browsers support */
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 1) 40%,
      rgba(0, 0, 0, 0.57) 55%,
      rgba(0, 0, 0, 0.2) 65%,
      rgba(0, 0, 0, 0) 89%,
      rgba(0, 0, 0, 0) 100%
    );
  }
`;

export const HeaderTitle = styled.h1.attrs({
  className: "text-6xl font-bold text-left",
})`
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 58px;
  letter-spacing: 0.05em;

  color: #0d56ea;
`;

export const HeaderTitleHighlight = styled.span.attrs({
  className: "",
})`
  color: #000000;
`;

export const HeaderSubtitle = styled.p.attrs({
  className: "py-8 text-2xl text-left",
})`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  /* or 125% */

  color: #050517;
`;

export const HeaderText = styled.p.attrs({
  className: "px-2 bg-black md:-mt-24  md:text-2xl text-xl text-center", //temporal negative margin while designing new header carousel
})`
  font-style: normal;
  font-weight: 500;
  /* or 125% */

  color: white;
`;

export const HeaderHighlightText = styled.span.attrs({
  className: "px-2 md:text-2xl text-xl text-center",
})`
  font-style: normal;
  font-weight: 500;

  /* or 125% */

  background-color: #d4f65f;
  color: black;
`;

export const HeaderSearchInput = styled.input.attrs({
  type: "text",
  placeholder: "Search",
  className: "input input-bordered w-9/12",
})`
  box-sizing: border-box;

  background: #ffffff;
  border: 1px solid #050517;
  border-bottom-left-radius: 25px !important;
  border-top-left-radius: 25px !important;

  ::placeholder {
    font-family: "Barlow";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    /* identical to box height */

    letter-spacing: 0.05em;

    color: #050517;

    opacity: 0.6;
  }
`;

export const HeaderSearchInputButton = styled.button.attrs({
  className: "btn btn-square w-3/12",
})`
  background: #5084ef;
  border-radius: 25px !important;
  border-color: #5084ef;

  margin-left: -25px;

  :hover {
    background-color: #0d56ea;
    border-color: #0d56ea;
  }
`;

export const HeaderIllustrationContainer = styled.div.attrs({
  className: "w-1/1 md:w-8/12",
})``;

export const HeaderFeaturedCardContainer = styled.div.attrs({
  className: "w-1/2",
})``;

export const HeaderFeaturedCard = styled.div.attrs({
  className: "card bg-base-100 shadow-xl rounded-xl",
})`
  :hover {
    animation: 1s ${pulseAnimation};
    cursor: poBarlow;
  }
`;

export const HeaderFeaturedCardTitle = styled.h2.attrs({
  className: "card-title justify-start",
})`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 120% */

  display: flex;
  align-items: center;
  letter-spacing: 0.015em;

  color: #000000;
`;

export const HeaderFeaturedCardUsername = styled.p.attrs({
  className: "text-left",
})`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 120% */

  display: flex;
  align-items: center;
  letter-spacing: 0.015em;

  color: #0d56ea;
`;

export const HeaderFeaturedCardPrice = styled.p.attrs({
  className: "w-3/12 self-start justify-self-end text-right",
})`
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 34px;
  /* identical to box height, or 189% */

  letter-spacing: 0.015em;

  color: #000000;
`;
