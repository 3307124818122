import styled from "styled-components";

/* Literature */

export const LiteratureContainer = styled.div.attrs({
  id: "literature",
  className: "Literature pt-20 relative",
})``;

export const LiteratureShadowImage = styled.div.attrs({
  className: "absolute top-0 bottom-0 left-0 right-0 z-30",
})`
   {
    /* ff 3.6+ */
    background: -moz-linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 1) 45%,
      rgba(0, 0, 0, 0.57) 70%,
      rgba(0, 0, 0, 0.4) 100%
    );

    /* safari 5.1+,chrome 10+ */
    background: -webkit-linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 1) 45%,
      rgba(0, 0, 0, 0.57) 70%,
      rgba(0, 0, 0, 0.4) 100%
    );

    /* opera 11.10+ */
    background: -o-linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 1) 45%,
      rgba(0, 0, 0, 0.57) 70%,
      rgba(0, 0, 0, 0.4) 100%
    );

    /* ie 6-9 */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=1 );

    /* ie 10+ */
    background: -ms-linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 1) 45%,
      rgba(0, 0, 0, 0.57) 70%,
      rgba(0, 0, 0, 0.4) 100%
    );

    /* global 94%+ browsers support */
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 1) 45%,
      rgba(0, 0, 0, 0.57) 70%,
      rgba(0, 0, 0, 0.4) 100%
    );
  }
`;

export const LiteratureText = styled.p.attrs({
  className: "text-white text-lg xs:text-justify  ",
})``;

export const LiteratureIllustrationContainer = styled.div.attrs({
  className: "md:w-4/12 w-10/12 block m-auto",
})``;

export const LiteratureSeeMoreButton = styled.button.attrs({
  className: "btn btn-link text-lg text-chinyellow normal-case p-0",
})``;

export const LiteratureDownloadButton = styled.button.attrs({
  className:
    "btn btn-outline text-md text-chinyellow border-chinyellow normal-case px-4 h-10 min-h-0 rounded-full font-semibold",
})``;
