import React from "react";

import {
  IntroductionContainer,
  IntroductionText,
  IntroductionIllustrationContainer,
} from "../styles/introduction";

import { ButtonContainer, YellowTitle } from "../styles/styles";

import { MdDownload } from "react-icons/md";

import chin_illustration from "../assets/images/chin-illustration.png";

import capi_perez from "../assets/video/capi_perez.mp4";
import paola_rojas from "../assets/video/paola_rojas.mp4";
import amairani_garza from "../assets/video/amairani_garza.mp4";

function Introduction() {
  return (
    <IntroductionContainer>
      <div className="flex justify-center mx-5 sm:mx-20 lg:mx-40 xl:mx-80 2xl:mx-96">
        <YellowTitle>¿CÓMO LOS INFLUENCERS PROMUEVEN PRODUCTOS NO SALUDABLES?</YellowTitle>
      </div>
      <div className="flex flex-col justify-center items-center mx-5 sm:mx-20 lg:mx-40 xl:mx-80 2xl:mx-96 pt-20">
        <div className="grid grid-flow-row auto-rows-max md:flex md:flex-row justify-center">
          <div className="order-last mt-auto mb-auto text-justify md:text-start md:order-first md:w-7/12 pt-5 md:pr-10">
            <IntroductionText>
              Los hábitos alimentarios se definen principalmente durante la
              infancia y juventud. Por esta razón la industria alimentaria
              cuenta con un gran interés en que niños, niñas y adolescentes
              (NNA) se conviertan en fieles consumidores de sus marcas; por lo
              que crean estrategias de empatía con sus productos desde los
              primeros años de vida de las personas. Hoy en día las y los NNA
              consumen cada vez menos televisión y al mismo tiempo gastan varias
              horas al día frente a su celular, computadora o tableta. Esta
              tendencia de cambio de hábitos se ha acelerado todavía más por la
              reciente pandemia del COVID-19.
            </IntroductionText>
          </div>
          <div className="md:w-4/12 w-7/12 block ml-auto mr-auto">
            <video
              className="ml-auto mr-auto"
              width="250px"
              height="auto"
              controls
              playsInline
            >
              <source src={capi_perez} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <p className="text-white ml-auto mr-auto w-[250px] text-xs xs:text-justify flex items-center mt-5">
              El Capi Pérez, @elcapiperez 4.2 millones seguidores, grabación de
              pantalla de su cuenta en Instagram tomada el 13.10.2021
            </p>
          </div>
          {/* <IntroductionIllustrationContainer>
            <img
              className=" align-center"
              src={chin_illustration}
              alt="Chatarra Influencer Illustration"
            />
          </IntroductionIllustrationContainer> */}
        </div>
        <div className="md:flex md:flex-row justify-center pt-10 md:pt-5 pb-5 mt-10">
          <div className="md:w-4/12 w-7/12 block ml-auto mr-auto">
            <video
              className="ml-auto mr-auto"
              width="250px"
              height="auto"
              controls
              playsInline
            >
              <source src={paola_rojas} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <p className="text-white ml-auto mr-auto w-[250px] text-xs xs:text-justify flex items-center mt-5">
              Paola Rojas, @paolarojas 1.9 millones de seguidores, grabación de
              pantalla de su cuenta en Instagram tomada el 01.11.2022
            </p>
          </div>
          {/* <IntroductionIllustrationContainer>
            <img
              src={chin_illustration}
              alt="Chatarra Influencer Illustration"
            />
          </IntroductionIllustrationContainer> */}
          <div className="text-justify mt-auto mb-auto md:text-start md:w-7/12 pt-5 md:pl-10">
            <IntroductionText>
              Una de las estrategias que han utilizado las agencias para
              promocionar la comida chatarra a través de redes sociales son
              campañas con influencers. Aprovechar el gran alcance que tienen
              las y los influencers, así como la conexión cercana que tienen con
              sus seguidores: Instagram es la red social clave para el marketing
              de influencers. Y aunque cuentas informativas como las del
              periódico La Jornada y la Revista Proceso cuentan apenas con 200
              mil y 553 mil seguidores, respectivamente; otras como la del
              influencer Luisito Comunica (@luisitocomunica), cuyo nombre
              verdadero es Luis Arturo Villar Sudek, cuentan con 29.1 millones.
            </IntroductionText>
          </div>
        </div>
        <div className="grid grid-flow-row auto-rows-max md:flex md:flex-row justify-center mt-10">
          <div className="order-last mt-auto mb-auto text-justify md:text-start md:order-first md:w-7/12 pt-5 md:pr-10">
            <IntroductionText>
              Otra característica de los influencers de la que se vale la
              industria de comida chatarra para sus campañas de publicidad es la
              especialización que tienen los influencers. Por ejemplo, existen
              las mamás influencers que promocionan leches o yogures
              “saludables” para sus hijos e hijas. O existen los influencers
              gamers que promocionan que promocionan marcas de comida chatarra
              populares entre los gamers.
            </IntroductionText>
          </div>
          <div className="md:w-4/12 w-7/12 block ml-auto mr-auto">
            <video
              className="ml-auto mr-auto"
              width="250px"
              height="auto"
              controls
              playsInline
            >
              <source src={amairani_garza} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <p className="text-white ml-auto mr-auto w-[250px] text-xs xs:text-justify flex items-center mt-5">
              Amairani Garza,@arigameplays, 11.9 millones de seguidores,
              grabación de pantalla de su cuenta de Instagram tomada el
              13.11.2021
            </p>
          </div>
          {/* <IntroductionIllustrationContainer>
            <img
              className=" align-center"
              src={chin_illustration}
              alt="Chatarra Influencer Illustration"
            />
          </IntroductionIllustrationContainer> */}
        </div>
      </div>

      {/* <div className="flex justify-center">
        <a href="#">
          <ButtonContainer>
            <div>
              <p className="text-sm text-end uppercase text-white">Descargar</p>
              <p className="text-lg text-end font-bold uppercase text-chinred italic">
                informe completo
              </p>
            </div>
            <div className="btn btn-circle ml-2 text-[25px]">
              <MdDownload />
            </div>
          </ButtonContainer>
        </a>
      </div> */}
    </IntroductionContainer>
  );
}

export default Introduction;
