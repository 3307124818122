import React from "react";
import { FaInstagram, FaRegWindowClose, FaTwitter } from "react-icons/fa";
import {
  MdFacebook,
  MdShortText,
  MdEditNote,
  MdFastfood,
  MdMenuBook,
  MdPersonSearch,
  MdCampaign,
  MdLabelImportant,
  MdWavingHand,
  MdArrowDropDownCircle,
  MdSummarize,
  MdReport,
  MdGppBad,
  MdClose,
  MdArrowForward,
} from "react-icons/md";

import { NavLogoTitle, NavLogoSubTitle } from "../styles/logo";

import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";

import AOS from "aos";
import "aos/dist/aos.css";
import { IoIosClose, IoIosCloseCircleOutline } from "react-icons/io";

import { HashLink } from "react-router-hash-link";

AOS.init();

const Navbar = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const toggleDrawer = () => {
    setIsOpen(prevState => !prevState);
  };
  return (
    <div
      data-aos="slide-down"
      data-aos-anchor-placement="top-top"
      data-aos-duration="1000"
      className="navbar fixed top-0 border-b-[2px] border-zinc-900 z-[100] bg-black text-white"
    >
      <div className="flex justify-between w-full xl:mx-20">
        <div>
          {/* DRAWER */}
          <label
            onClick={toggleDrawer}
            className="btn btn-sm btn-ghost rounded-none"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 hover:text-chinyellow active:text-chinpink"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h7"
              />
            </svg>
          </label>
          <Drawer
            open={isOpen}
            onClose={toggleDrawer}
            direction="left"
            className="text-xl text-white bg-black font-barlow"
          >
            <div className="content">
              <ul className="md:text-lg text-md dropdown-content mt-3 m-5 shadow bg-black h-screen overflow-y-auto w-80">
                <label
                  onClick={toggleDrawer}
                  className="btn btn-sm btn-ghost rounded-none"
                >
                  <IoIosCloseCircleOutline className="text-2xl" />
                </label>
                <div className="lg:hidden">
                  <li className="mb-1 text-zinc-500 pl-[60px] text-lg">MENÚ</li>
                  <li className="hover:bg-chinyellow hover:text-black active:bg-chinblue active:text-white py-2">
                    <a
                      className="flex flex-row items-center active:bg-transparent active:text-black min-[321px]:py-[6px] py-[2px]"
                      href="#"
                    >
                      <MdLabelImportant className="mx-4 text-xl" />
                      Inicio
                    </a>
                  </li>
                  <li className="hover:bg-chinyellow hover:text-black active:bg-chinblue active:text-white py-2">
                    <HashLink
                      to={{
                        pathname: "/",
                        hash: "#denuncia",
                      }}
                      className="flex flex-row items-center active:bg-transparent active:text-black min-[321px]:py-[6px] py-[2px]"
                    >
                      <MdLabelImportant className="mx-4 text-xl" />
                      Denuncia
                    </HashLink>
                  </li>
                  <li className="hover:bg-chinyellow hover:text-black active:bg-chinblue active:text-white py-2">
                    <a
                      className="flex flex-row items-center active:bg-transparent active:text-black min-[321px]:py-[6px] py-[2px]"
                      href="https://www.tec-check.com.mx/wp-content/uploads/2023/04/Informe-ChatarraInfluencer-Tec-Check-Poder-del-Consumidor.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <MdLabelImportant className="mx-4 text-xl" />
                      Informe #ChatarraInfluencer
                    </a>
                  </li>
                  <div className="w-full h-[1px] bg-zinc-900 mb-3"></div>
                </div>
                <li className="mb-1 text-zinc-500 pl-[60px] text-lg">ÍNDICE</li>
                <li className="hover:bg-chinyellow hover:text-black active:bg-chinblue active:text-white py-2">
                  <a
                    className="flex flex-row items-center active:bg-transparent active:text-black min-[321px]:py-[6px] py-[2px]"
                    href="#recent"
                  >
                    <MdCampaign className="mx-4 text-2xl" />
                    Campañas Recientes
                  </a>
                </li>
                <div className="w-full h-[1px] bg-zinc-900"></div>
                <li className="hover:bg-chinyellow hover:text-black active:bg-chinblue active:text-white py-2">
                  <a
                    className="flex flex-row items-center active:bg-transparent active:text-black min-[321px]:py-[6px] py-[2px]"
                    href="#campaigns"
                  >
                    <MdCampaign className="mx-4 text-2xl" />
                    Campañas #ChatarraInfluencers
                  </a>
                </li>
                <div className="w-full h-[1px] bg-zinc-900"></div>

                <li className="hover:bg-chinyellow hover:text-black active:bg-chinblue active:text-white py-2">
                  <a
                    className="flex flex-row items-center active:bg-transparent active:text-black min-[321px]:py-[6px] py-[2px]"
                    href="#resumen"
                  >
                    <MdSummarize className="mx-4 text-2xl" />
                    ¿Por qué tener un observatorio ciudadano de influencers?{" "}
                  </a>
                </li>
                <div className="w-full h-[1px] bg-zinc-900"></div>
                <li className="hover:bg-chinyellow hover:text-black active:bg-chinblue active:text-white py-2">
                  <a
                    className="flex flex-row items-center active:bg-transparent active:text-black min-[321px]:py-[6px] py-[2px]"
                    href="#introduction"
                  >
                    <MdShortText className="mx-4 text-2xl" />
                    ¿Cómo los influencers promueven productos no saludables?
                  </a>
                </li>
                <div className="w-full h-[1px] bg-zinc-900"></div>
                {/* <li className="hover:bg-chinyellow hover:text-black active:bg-chinblue active:text-white py-2">
              <a className="flex flex-row items-center active:bg-transparent active:text-black min-[321px]:py-[6px] py-[2px]" href="#blog">
                <MdEditNote className="mx-4 text-2xl"/>Blog
              </a>
            </li>
              <div className="dw-full h-[1px] bg-zinc-900></div>
            */}
                <li className="hover:bg-chinyellow hover:text-black active:bg-chinblue active:text-white py-2">
                  <a
                    className="flex flex-row items-center active:bg-transparent active:text-black min-[321px]:py-[6px] py-[2px]"
                    href="#influencers"
                  >
                    <MdFastfood className="mx-4 text-2xl" />
                    Influencers, publicidad y comida chatarra
                  </a>
                </li>
                <div className="w-full h-[1px] bg-zinc-900"></div>
                <li className="hover:bg-chinyellow hover:text-black active:bg-chinblue active:text-white py-2">
                  <a
                    className="flex flex-row items-center active:bg-transparent active:text-black min-[321px]:py-[6px] py-[2px]"
                    href="#literature"
                  >
                    <MdMenuBook className="mx-4 text-2xl" />
                    Evidencia Científica
                  </a>
                </li>
                <div className="w-full h-[1px] bg-zinc-900"></div>
                <li className="hover:bg-chinyellow hover:text-black active:bg-chinblue active:text-white py-2">
                  <a
                    className="flex flex-row items-center active:bg-transparent active:text-black min-[321px]:py-[6px] py-[2px]"
                    href="#regulacion"
                  >
                    <MdPersonSearch className="mx-4 text-2xl" />
                    Regulación a la publicidad con influencers
                  </a>
                </li>
                <div className="w-full h-[1px] bg-zinc-900"></div>
                <li className="hover:bg-chinyellow hover:text-black active:bg-chinblue active:text-white py-2">
                  <a
                    className="flex flex-row items-center active:bg-transparent active:text-black min-[321px]:py-[6px] py-[2px]"
                    href="#conclusions"
                  >
                    <MdLabelImportant className="mx-4 text-2xl" />
                    ¿Qué se debe hacer?
                  </a>
                </li>
                <div className="w-full h-[1px] bg-zinc-900"></div>
                <li className="hover:bg-chinyellow hover:text-black active:bg-chinblue active:text-white py-2">
                  <HashLink
                    to={{
                      pathname: "/",
                      hash: "#denuncia",
                    }}
                    className="flex flex-row items-center active:bg-transparent active:text-black min-[321px]:py-[6px] py-[2px]"
                  >
                    <MdReport className="mx-4 text-2xl" />
                    Denuncia una campaña #ChatarraInfluencer
                  </HashLink>
                </li>
                <div className="w-full h-[1px] bg-zinc-900"></div>
                <li className="hover:bg-chinyellow hover:text-black active:bg-chinblue active:text-white py-2">
                  <a
                    className="flex flex-row items-center active:bg-transparent active:text-black min-[321px]:py-[6px] py-[2px]"
                    href="#nosotros"
                  >
                    <MdWavingHand className="mx-4 text-2xl" />
                    Nosotros
                  </a>
                </li>
                <div className="dw-full h-[1px] bg-zinc-900"></div>

                {/* <li className="hover:bg-chinyellow hover:text-black active:bg-chinblue active:text-white py-2">
                  <a className="flex flex-row items-center active:bg-transparent active:text-black min-[321px]:py-[6px] py-[2px]" href="#influencerpeor">
                    <MdGppBad className="mx-4 text-2xl" />#ComoInfluencerTeVesPeor
                  </a>
                </li>
                <div className="w-full h-[1px] bg-zinc-900"></div> */}
              </ul>
            </div>
          </Drawer>
          {/* TERMINA DRAWER */}
        </div>

        <div className="flex flex-row">
          <a href="#">
            <NavLogoTitle>#CHATARRA </NavLogoTitle>
            <NavLogoSubTitle>INFLUENCER</NavLogoSubTitle>
          </a>
        </div>

        <div className="hidden lg:flex flex-row">
          <a
            href="#"
            className="btn btn-ghost btn-sm transition ease-in-out hover:-translate-y-1 hover:scale-105 duration-200 lg:px-3 sm:px-2 px-1 hover:text-chinpink active:text-chinyellow"
          >
            Inicio
          </a>
          
          <a
            href="https://www.tec-check.com.mx/wp-content/uploads/2023/04/Informe-ChatarraInfluencer-Tec-Check-Poder-del-Consumidor.pdf"
            target="_blank"
            rel="noreferrer"
            className="btn btn-ghost btn-sm transition ease-in-out hover:-translate-y-1 hover:scale-105 duration-200 lg:px-3 sm:px-2 px-1 hover:text-chinpink active:text-chinyellow"
          >
            Informe #chatarrainfluencer
          </a>
          <HashLink
            to={{
              pathname: "/",
              hash: "#denuncia",
            }}
            className="flex items-center transition ease-in-out hover:-translate-y-1 hover:scale-105 duration-200"
          >
            <p className="text-lg text-barlow text-end font-bold uppercase text-chinred italic active:text-chinpurple">Denuncia</p>
            <MdArrowForward className="btn btn-sm btn-circle ml-2 p-2 "/>
          </HashLink>
        </div>

        <div className="flex flex-row">
          <a
            href="https://www.instagram.com/teccheckmx/"
            target="_blank"
            className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 lg:px-3 sm:px-2 px-1 hover:text-chinpink active:text-chinyellow sm:text-[20px] text-[15px]"
            rel="noreferrer"
          >
            <FaInstagram />
          </a>
          <a
            href="https://twitter.com/teccheckmx"
            target="_blank"
            className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 lg:px-3 sm:px-2 px-1 hover:text-chinpink active:text-chinyellow sm:text-[20px] text-[15px]"
            rel="noreferrer"
          >
            <FaTwitter />
          </a>
          <a
            href="https://www.facebook.com/teccheckmx/"
            target="_blank"
            className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 lg:px-3 sm:px-2 px-1 hover:text-chinpink active:text-chinyellow sm:text-[20px] text-[15px]"
            rel="noreferrer"
          >
            <MdFacebook />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
