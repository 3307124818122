import React from "react";

import {
  RegulacionContainer,
  RegulacionText,
  RegulacionSeeMoreButton,
  CarrouselContainer,
  CarrouselArrowLeft,
  CarrouselArrowRight,
  CarrouselContent,
  CarrouselCard,
  CarrouselCardTitle,
  RegulacionCollapse,
  CollapseTitle,
  CollapseContent,
  ContentTable,
  CountryA,
  CountryB,
  ContentA,
  ContentB,
} from "../styles/regulacion";

import Carousel from "../components/RegulationCarousel";

import chin_illustration_alt from "../assets/images/chin-illustration-alt.png";

import { BlueTitle } from "../styles/styles";

const data = [
  {
    title: "Argentina",
    excerpt:
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euis- mod tincidunt ut laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euis- mod tincidunt ut laoreet dolore magna aliquam erat volutpat.",
    link: "https://google.com",
    imageUrl: chin_illustration_alt,
  },
  {
    title: "Estados Unidos",
    excerpt:
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euis- mod tincidunt ut laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euis- mod tincidunt ut laoreet dolore magna aliquam erat volutpat.",
    link: "https://google.com",
    imageUrl: chin_illustration_alt,
  },
  {
    title: "México",
    excerpt:
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euis- mod tincidunt ut laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euis- mod tincidunt ut laoreet dolore magna aliquam erat volutpat.",
    link: "https://google.com",
    imageUrl: chin_illustration_alt,
  },
  {
    title: "Reino Unido",
    excerpt:
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euis- mod tincidunt ut laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euis- mod tincidunt ut laoreet dolore magna aliquam erat volutpat.",
    link: "https://google.com",
    imageUrl: chin_illustration_alt,
  },
  {
    title: "Argentina",
    excerpt:
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euis- mod tincidunt ut laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euis- mod tincidunt ut laoreet dolore magna aliquam erat volutpat.",
    link: "https://google.com",
    imageUrl: chin_illustration_alt,
  },
  {
    title: "Estados Unidos",
    excerpt:
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euis- mod tincidunt ut laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euis- mod tincidunt ut laoreet dolore magna aliquam erat volutpat.",
    link: "https://google.com",
    imageUrl: chin_illustration_alt,
  },
  {
    title: "México",
    excerpt:
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euis- mod tincidunt ut laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euis- mod tincidunt ut laoreet dolore magna aliquam erat volutpat.",
    link: "https://google.com",
    imageUrl: chin_illustration_alt,
  },
  {
    title: "Reino Unido",
    excerpt:
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euis- mod tincidunt ut laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euis- mod tincidunt ut laoreet dolore magna aliquam erat volutpat.",
    link: "https://google.com",
    imageUrl: chin_illustration_alt,
  },
];

function Regulacion() {
  return (
    <RegulacionContainer>
      <div className="flex justify-center mx-5 sm:mx-20 lg:mx-40 xl:mx-80 2xl:mx-96 ">
        <BlueTitle>Regulación a la publicidad con influencers</BlueTitle>
      </div>
      <div className="mx-5 sm:mx-20 lg:mx-40 xl:mx-80 2xl:mx-96 mb-5">
        <RegulacionText>
          Para respetar el derecho a publicidad identificable y que las y los
          consumidores puedan tomar decisiones informadas, las instituciones
          regulatorias alrededor del mundo han implementado medidas para hacer
          la publicidad de influencers transparente.
          <br />
          <br />
          <a
            className="underline underline-offset-2 font-bold hover:text-chinblue"
            href="https://onlinelibrary.wiley.com/doi/10.1111/obr.12678"
            target="_blank"
            rel="noreferrer"
          >
            Kovic et al. (2018)
          </a>{" "}
          mostraron, con base en un análisis de 79 países, que solamente en los
          países que implementaron medidas de normatividad en materia de
          publicidad para comida chatarra hubo una disminución de consumo de
          8.9%. Por el contrario, en los países que optaron por una
          autorregulación, el consumo aumentó 1.7%.
        </RegulacionText>
      </div>

      {/* CARROUSEL */}

      {/* <div>
        <Carousel data={data} />
      </div>*/}

      <div className="mx-5 sm:mx-20 lg:mx-40 xl:mx-80 2xl:mx-96 mb-10">
        <RegulacionText>
          El intento de autorregulación en México, conocido como{" "}
          <a
            className="underline underline-offset-2 font-bold hover:text-chinblue"
            href="https://www.dof.gob.mx/nota_detalle.php?codigo=5612351&fecha=26/02/2021"
            target="_blank"
            rel="noreferrer"
          >
            Código de Ética en materia de Comercio Electrónico
          </a>
          , ha sido un fracaso y aún peor, ha servido como respaldo de las malas
          prácticas por parte de la industria.
        </RegulacionText>
      </div>

      {/* Collapse */}

      <div className="mx-5 sm:mx-20 lg:mx-40 xl:mx-60">
        <RegulacionCollapse>
          <input type="checkbox" className="peer" />
          <CollapseTitle>Características de publicidad engañosa</CollapseTitle>
          <CollapseContent>
            <ContentTable>
              <div>
                <CountryA>Alemania</CountryA>
                <ContentA>
                  · Presentación de productos, proveedores de productos o
                  servicios, cuando el presentador lo hace con intenciones de
                  promocionarlos.
                  <br />
                  <br />· Es necesario trasparentar la publicidad para
                  desencaminar a la población consumidora.
                </ContentA>
              </div>
              <div>
                <CountryB>Argentina</CountryB>
                <ContentB>
                  · Contiene indicaciones falsas.
                  <br />
                  <br />
                  · Efectúa comparaciones que conducen al error.
                  <br />
                  <br />· Es abusiva, discriminatoria o induce al consumidor a
                  comportarse de forma perjudicial a su salud.
                </ContentB>
              </div>
              <div>
                <CountryA>Estados Unidos</CountryA>
                <ContentA>
                  · Afecta el comportamiento o decisiones del consumidor.
                  <br />
                  <br />· Omite información necesaria para tener expectativas
                  razonables.
                </ContentA>
              </div>
              <div>
                <CountryB>México</CountryB>
                <ContentB>
                  · Induce al error o confusión por la forma inexacta, falsa,
                  exagerada, parcial o tendenciosa con la que se presenta.
                </ContentB>
              </div>
              <div>
                <CountryA>Reino Unido</CountryA>
                <ContentA>
                  · Incluye falsa información.
                  <br />
                  <br />
                  · Omite información importante.
                  <br />
                  <br />· Utiliza técnicas agresivas de venta.
                </ContentA>
              </div>
            </ContentTable>
          </CollapseContent>
        </RegulacionCollapse>
        <RegulacionCollapse>
          <input type="checkbox" className="peer" />
          <CollapseTitle>
            Criterios que determinan que el contenido de influencers es
            publicitario
          </CollapseTitle>
          <CollapseContent>
            <ContentTable>
              <div>
                <CountryA>Alemania</CountryA>
                <ContentA>
                  A partir de mayo 2022, se asume que todo contenido publicado
                  por influencers es publicidad. Por lo tanto, las y los
                  influencers tendrán que evidenciar que no recibieron ningún
                  tipo de compensación para la promoción de un producto o
                  servicio.
                </ContentA>
              </div>
              <div>
                <CountryB>Argentina</CountryB>
                <ContentB>
                  De acuerdo con el Proyecto de Ley:
                  <br />
                  <br />
                  · Unboxing.
                  <br />
                  <br />
                  · Sorteos.
                  <br />
                  <br />· Fotos en los que se muestre el producto.
                </ContentB>
              </div>
              <div>
                <CountryA>Estados Unidos</CountryA>
                <ContentA>
                  Existe conexión material entre el influencer y la marca, la
                  cual se puede manisfestar en:
                  <br />
                  <br />
                  · Pago monetario o en especie.
                  <br />
                  <br />
                  · Comisión a través de enlace de afiliados.
                  <br />
                  <br />
                  · Relación familiar o de amistad.
                  <br />
                  <br />· Regalo.
                </ContentA>
              </div>
              <div>
                <CountryB>México</CountryB>
                <ContentB>N/A</ContentB>
              </div>
              <div>
                <CountryA>Reino Unido</CountryA>
                <ContentA>
                  El anunciante tiene control sobre el contenido y compensa al
                  productor. Por ejemplo:
                  <br />
                  <br />
                  · Control: la marca da una pauta sobre el mensaje y solicita
                  aprobación antes de publicarla.
                  <br />
                  <br />· Compensación: regalo, enlace de afiliado, beneficio
                  económico.
                </ContentA>
              </div>
            </ContentTable>
          </CollapseContent>
        </RegulacionCollapse>
        <RegulacionCollapse>
          <input type="checkbox" className="peer" />
          <CollapseTitle>
            Especificaciones relevantes al momento de declarar
          </CollapseTitle>
          <CollapseContent>
            <ContentTable>
              <div>
                <CountryA>Alemania</CountryA>
                <ContentA>N/A</ContentA>
              </div>
              <div>
                <CountryB>Argentina</CountryB>
                <ContentB>
                  Incluir la etiqueta #PublicaciónPaga y la reponsabilidad recae
                  únicamente en influencers.
                </ContentB>
              </div>
              <div>
                <CountryA>Estados Unidos</CountryA>
                <ContentA>
                  En el caso de publicaciones en Instagram, la declaración no
                  puede estar oculta por la función “... más”.
                </ContentA>
              </div>
              <div>
                <CountryB>México</CountryB>
                <ContentB>N/A</ContentB>
              </div>
              <div>
                <CountryA>Reino Unido</CountryA>
                <ContentA>
                  Utilizar etiquetas de colores legibles en las historias y
                  declarar al inicio del video.
                </ContentA>
              </div>
            </ContentTable>
          </CollapseContent>
        </RegulacionCollapse>
        <RegulacionCollapse>
          <input type="checkbox" className="peer" />
          <CollapseTitle>Documento regulatorio</CollapseTitle>
          <CollapseContent>
            <ContentTable>
              <div>
                <CountryA>Alemania</CountryA>
                <ContentA>
                  Ley de Competencia Desleal (Gesetz gegen den unlauteren
                  Wettbewerb).
                  <br />
                  <br />
                  Nuevo tratado estatal sobre medios de comunicación sustituirá
                  al tratado sobre radiodifusión (Medienstaatsvertrag).
                </ContentA>
              </div>
              <div>
                <CountryB>Argentina</CountryB>
                <ContentB>
                  · Guía sobre el uso de influencers en la comunicación
                  comercial publicada por la CONARP.
                  <br />
                  <br />· Proyecto de Ley presentado en el Senado.
                </ContentB>
              </div>
              <div>
                <CountryA>Estados Unidos</CountryA>
                <ContentA>Guía de aprobación.</ContentA>
              </div>
              <div>
                <CountryB>México</CountryB>
                <ContentB>N/A</ContentB>
              </div>
              <div>
                <CountryA>Reino Unido</CountryA>
                <ContentA>
                  Código de la publicidad no televisiva y del marketing
                  promocional directo (Código CAP).
                </ContentA>
              </div>
            </ContentTable>
          </CollapseContent>
        </RegulacionCollapse>
        <RegulacionCollapse>
          <input type="checkbox" className="peer" />
          <CollapseTitle>
            Autoridad encargada de regular la publicidad
          </CollapseTitle>
          <CollapseContent>
            <ContentTable>
              <div>
                <CountryA>Alemania</CountryA>
                <ContentA>Tribunales estatales.</ContentA>
              </div>
              <div>
                <CountryB>Argentina</CountryB>
                <ContentB>
                  · Dirección Nacional de Defensa del Consumidor.
                  <br />
                  <br />· Consejo de Autorregulación Publicitaria de la
                  Argentina (CONARP).
                </ContentB>
              </div>
              <div>
                <CountryA>Estados Unidos</CountryA>
                <ContentA>Comisión Federal de Comercio (FTC).</ContentA>
              </div>
              <div>
                <CountryB>México</CountryB>
                <ContentB>
                  · Procuradoría Federal del Consumidor (PROFECO).
                  <br />
                  <br />· Consejo de Autorregulación y Ética Publicitaria
                  (CONAR).
                </ContentB>
              </div>
              <div>
                <CountryA>Reino Unido</CountryA>
                <ContentA>
                  · Autoridad de Normas de Publicidad (ASA) · Comité de
                  Prácticas Publicitarias (CAP) · Autoridad de Competencia y los
                  Mercados (CMA).
                </ContentA>
              </div>
            </ContentTable>
          </CollapseContent>
        </RegulacionCollapse>
        <RegulacionCollapse>
          <input type="checkbox" className="peer" />
          <CollapseTitle>
            Asociaciones relevantes de monitoreo e investigación
          </CollapseTitle>
          <CollapseContent>
            <ContentTable>
              <div>
                <CountryA>Alemania</CountryA>
                <ContentA>
                  · Cada entidad federativa tiene su propia agenda de medios
                  para monitorear e iniciar procedimientos por infracción al
                  Medienstaatsvertrag.
                </ContentA>
              </div>
              <div>
                <CountryB>Argentina</CountryB>
                <ContentB>N/A</ContentB>
              </div>
              <div>
                <CountryA>Estados Unidos</CountryA>
                <ContentA>
                  · En 2017, la FTC envió cartas de aviso a más de 90
                  influencers.
                  <br />
                  <br />
                  · En 2019, la FTC envió una carta a empresa productora de
                  tabaco.
                  <br />
                  <br />· En 2020 varias OSCs enviaron postura sobre marketing
                  de influencers infantiles para demandar a la FTC un marco
                  normativo más riguroso.
                </ContentA>
              </div>
              <div>
                <CountryB>México</CountryB>
                <ContentB>N/A</ContentB>
              </div>
              <div>
                <CountryA>Reino Unido</CountryA>
                <ContentA>
                  · En 2021 publicaron el primer reporte de resultados de
                  monitoreo.
                  <br />
                  <br />· En 2021 el parlamento británico inició discusión
                  pública sobre el tema.
                </ContentA>
              </div>
            </ContentTable>
          </CollapseContent>
        </RegulacionCollapse>
        <RegulacionCollapse>
          <input type="checkbox" className="peer" />
          <CollapseTitle>En caso de incumplimiento:</CollapseTitle>
          <CollapseContent>
            <ContentTable>
              <div>
                <CountryA>Alemania</CountryA>
                <ContentA>
                  · En caso de incumplimiento, las agencias pueden denunciar a
                  influencers ante una fiscalía.
                  <br />
                  <br />· Además, cada ciudadana o persona moral puede denunciar
                  a influencers con base, por ejemplo, en el ley de Competencia
                  Desleal.
                </ContentA>
              </div>
              <div>
                <CountryB>Argentina</CountryB>
                <ContentB>
                  De acuerdo con el Proyecto de Ley propone:
                  <br />
                  <br />
                  · Apercibimiento.
                  <br />
                  <br />
                  · Retiro de anuncio.
                  <br />
                  <br />
                  · Rectificación de contenido.
                  <br />
                  <br />· Multas.
                </ContentB>
              </div>
              <div>
                <CountryA>Estados Unidos</CountryA>
                <ContentA>
                  La FTC puede iniciar una investigación sobre las prácticas de
                  publicidad para determinar si se ha comentido una falta al
                  marco legal que regula la publicidad, el cual incluye
                  sanciones económicas.
                </ContentA>
              </div>
              <div>
                <CountryB>México</CountryB>
                <ContentB>N/A</ContentB>
              </div>
              <div>
                <CountryA>Reino Unido</CountryA>
                <ContentA>
                  La ASA publica anuncios en sus redes sociales sobre los
                  perfiles de influencers que han incumplido el Código CAP.
                  <br />
                  <br />
                  La CAP solicitó a Facebook e Instagram tomar medidas para
                  detectar y eliminar contenido publicitario engañoso.
                </ContentA>
              </div>
            </ContentTable>
          </CollapseContent>
        </RegulacionCollapse>
      </div>
    </RegulacionContainer>
  );
}

export default Regulacion;
