import styled from "styled-components";

/* Footer */

export const FooterContainer = styled.div.attrs({
  className: "footer text-white bg-zinc-800 block",
})``;

export const FooterGrid = styled.div.attrs({
  className: "p-20 md:grid justify-items-center sm:block md:grid-cols-2 lg:grid-cols-4",
})`
  grid-auto-flow: unset;
`;

export const FooterTitle = styled.span.attrs({
  className: "capitalize mb-4",
})`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 40px;
  /* identical to box height, or 125% */

  letter-spacing: 0.015em;

  /* Background */

  color: #fbfbff;
`;

export const FooterDescription = styled.p.attrs({
  className: "link link-hover",
})`
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  /* or 150% */

  letter-spacing: 0.015em;

  /* Background */

  color: #fbfbff;
`;

export const FooterLink = styled.a.attrs({
  className: "link link-hover",
})`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  /* identical to box height, or 150% */

  letter-spacing: 0.015em;

  /* Background */

  color: #fbfbff;
`;

export const FooterCopyright = styled.p.attrs({
  className: "flex justify-center pb-3",
})`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* or 150% */

  text-align: center;
  letter-spacing: 0.015em;

  /* Background */

  color: #fbfbff;
`;
