import styled from "styled-components";

export const RecentContainer = styled.div.attrs({
  id: "recent",
  className: "py-[80px]",
})``;

export const RecentTitleContainer = styled.div.attrs({
  className: "flex flex-col items-center -mb-[150px]",
})``;

export const RecentCarouselContainer = styled.div.attrs({
  className: "carousel flex-row w-full",
})``;

export const RecentSlide = styled.div.attrs({
  className:
    "carousel-item pt-[200px]  flex-col items-center justify-center relative w-full",
})``;

export const RecentSlide1 = styled.div.attrs({
  id: "slide1",
  className: "carousel-item pt-[200px] flex-col items-center relative w-full",
})``;

export const RecentSlide2 = styled.div.attrs({
  id: "slide2",
  className: "carousel-item pt-[200px] flex-col items-center relative w-full",
})``;

export const RecentSlide3 = styled.div.attrs({
  id: "slide3",
  className: "carousel-item pt-[200px] flex-col items-center relative w-full",
})``;

export const RecentCardContainer = styled.div.attrs({
  className:
    "card hover:scale-105  ease-in-out duration-300 cursor-pointer rounded-none w-full md:w-1/2 2xl:w-5/12 bg-base-100 shadow-xl",
})``;

export const RecentCardBody = styled.div.attrs({
  className: "card-body bg-zinc-800 text-white font-barlow text-center",
})``;

export const RecentCardTitle = styled.h2.attrs({
  className: "text-2xl font-bold",
})``;

export const RecentButtonsContainer = styled.div.attrs({
  className:
    "absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2",
})``;
