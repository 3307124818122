import React from "react";
import { MdArrowForward, MdFacebook } from "react-icons/md";
import { FaInstagram, FaTwitter } from "react-icons/fa";

import { BlueTitle } from "../styles/styles";
import logo_techeck from "../assets/images/logo-techeck.png";
import logoEPC from "../assets/images/logoEPC.png";
import avatar_01 from "../assets/images/avatar-01.png";
import avatar_02 from "../assets/images/avatar-02.png";

function Nosotros() {
  return (
    <div id="nosotros" className="pt-20">
      <div className="flex justify-center relative z-40 pb-10">
        <BlueTitle>¿Quiénes somos?</BlueTitle>
      </div>

      <div className="mx-5 sm:mx-20 lg:mx-40 xl:mx-80 2xl:mx-96 pb-5 md:pb-10 text-chinwhite">
        <p className="text-justify text-lg">
          Este observatorio es un proyecto de{" "}
          <a
            href="https://www.tec-check.com.mx/"
            className="underline hover:text-chinblue"
            target="_blank"
            rel="noreferrer"
          >
            Tec-Check Organización de Consumidores en Línea A.C.
          </a>
          , realizado en estrecha cooperación con{" "}
          <a
            href="https://elpoderdelconsumidor.org/"
            className="underline hover:text-chinblue"
            target="_blank"
            rel="noreferrer"
          >
            El Poder del Consumidor A.C.
          </a>
          . Con este observatorio buscamos promover la discusión sobre nuestro
          derecho como consumidores a publicidad transparente, identificable,
          adecuada y veraz.
        </p>
        {/* <br /><div className="text-[15px] text-center uppercase">
          <a href="#">Lee más</a>
          <div className="btn btn-circle mx-2 text-[15px]">
            <MdArrowForward/>
          </div>
        </div> */}
      </div>

      {/* <div className="text-white m-5 sm:mx-20 lg:mx-60 xl:mx-[350px] 2xl:mx-[450px] z-1 grid md:grid-cols-2 justify-around justify-items-center">



                <div className="m-1">
          <a href="https://www.tec-check.com.mx/" className="hover:scale-110" target="_blank"><img className="w-60" src={logo_techeck} alt="TeCheck" /></a>
          <div className="flex pt-8 justify-center">
            <a
              href="https://www.instagram.com/teccheckmx/"
              target="_blank"
              className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 lg:px-3 sm:px-2 px-1 hover:text-chinpink active:text-chinyellow md:text-lg text-[15px]"
              rel="noreferrer"
            >
              <FaInstagram />
            </a>
            <a
              href="https://twitter.com/teccheckmx"
              target="_blank"
              className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 lg:px-3 sm:px-2 px-1 hover:text-chinpink active:text-chinyellow md:text-lg text-[15px]"
              rel="noreferrer"
            >
              <FaTwitter />
            </a>
            <a
              href="https://www.facebook.com/teccheckmx/"
              target="_blank"
              className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 lg:px-3 sm:px-2 px-1 hover:text-chinpink active:text-chinyellow md:text-lg text-[15px]"
              rel="noreferrer"
            >
              <MdFacebook />
            </a>
          </div>
        </div>



        <div className="m-1">
          <a href="https://elpoderdelconsumidor.org/" className="hover:scale-110" target="_blank"><img className="w-56" src={logoEPC} alt="El Poder del Consumidor" /></a>
          <div className="flex pt-6 justify-center">
            <a
              href="https://www.instagram.com/poderdelc/"
              target="_blank"
              className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 lg:px-3 sm:px-2 px-1 hover:text-chinpink active:text-chinyellow md:text-lg text-[15px]"
              rel="noreferrer"
            >
              <FaInstagram />
            </a>
            <a
              href="https://twitter.com/elpoderdelc"
              target="_blank"
              className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 lg:px-3 sm:px-2 px-1 hover:text-chinpink active:text-chinyellow md:text-lg text-[15px]"
              rel="noreferrer"
            >
              <FaTwitter />
            </a>
            <a
              href="https://www.facebook.com/elpoderdelc"
              target="_blank"
              className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 lg:px-3 sm:px-2 px-1 hover:text-chinpink active:text-chinyellow md:text-lg text-[15px]"
              rel="noreferrer"
            >
              <MdFacebook />
            </a>
          </div>
        </div>

        {/* Team Member 1*/}
      {/* <div className="pb-5">
          <img src={avatar_01}
            className="pr-5 -mb-10 w-72" />
          <div className="indicator">
            <span className="indicator-item bg-chingray text-white font-bold badge mr-10 rounded-none border-none">Equipo</span>
            <div className="collapse collapse-plus w-72 pl-5">
              <input type="checkbox" className="peer" />
              <div className="collapse-title peer-checked:font-bold bg-chinblue text-white peer-checked:bg-white peer-checked:text-black">
                Fiorentina García Miramón
              </div>
              <div className="text-justify collapse-content bg-chinblue text-white  peer-checked:bg-white peer-checked:text-black">
                <p>Fiorentina García Miramón es cofundadora de Tec-Check Organización de Consumidores en Línea A.C.
                  Se dedica a Tec-Check en su tiempo libre.
                  Cuenta con maestría en políticas públicas por la Hertie School of Governance (Berlín) y es licenciada en economía y ciencia política por el Tecnológico de Monterrey.
                  Su experiencia laboral está enfocada en análisis de políticas públicas sobre desarrollo económico, política industrial y finanzas públicas.
                  Ha sido consultora para organismos internacionales y de la sociedad civil en México y Alemania. Desde agosto 2020 es servidora pública.</p>
              </div>
            </div>
          </div>
        </div> */}
      {/* Team Member 2*/}
      {/* <div className="pb-5">
          <img src={avatar_02}
            className="pr-5 -mb-10 w-72" />
          <div className="indicator">
            <span className="indicator-item bg-chingray text-white font-bold badge mr-10 rounded-none border-none">Equipo</span>
            <div className="collapse collapse-plus w-72 pl-5">
              <input type="checkbox" className="peer" />
              <div className="collapse-title peer-checked:font-bold bg-chinblue text-white peer-checked:bg-white peer-checked:text-black">
                Maximilian Murck
              </div>
              <div className="text-justify collapse-content bg-chinblue text-white  peer-checked:bg-white peer-checked:text-black">
                <p>
                En México ha sido consultor para el Banco Mundial y director del programa de identificación humana de la Cooperación Alemana (GIZ). <br/>Actualmente, trabaja y se dedica a Tec-Check en su tiempo libre como compromiso social, sin recibir un peso.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="flex lg:mx-0 md:mx-20 mx-7 mb-10 justify-center">
      <p className=" text-white text-center">
        Desarrollado por <a href="https://www.tec-check.com.mx/" className="font-bold italic hover:text-chinblue" target="_blank">Tec-Check Organización de Consumidores en Línea A.C.</a>, con el apoyo de <a href="https://elpoderdelconsumidor.org/" className="font-bold italic hover:text-chinblue" target="_blank">El Poder del Consumidor A.C.</a>
      </p>
      </div> */}
    </div>
  );
}

export default Nosotros;
