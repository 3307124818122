import styled from "styled-components";

export const RegulacionContainer = styled.div.attrs({
  id: "regulacion", className: "h-full py-20 relative z-40",
})``;


export const RegulacionText = styled.p.attrs({
  className: "text-white text-justify mt-5 text-lg",
})``;

export const RegulacionSeeMoreButton = styled.button.attrs({
  className: "btn btn-link mb-5 text-lg text-chinyellow normal-case p-0",
})`
  font-family: "Barlow";
`;

export const CarrouselContainer = styled.div.attrs({
  className:
    "flex -mx-5 sm:-mx-10 lg:-mx-20 xl:-mx-30 flex-row self-center px-5 pt-5",
})``;

export const CarrouselArrowLeft = styled.div.attrs({
  className: "flex justify-center items-center text-white text-5xl pr-2",
})``;

export const CarrouselArrowRight = styled.div.attrs({
  className: "flex justify-center items-center text-white text-5xl pl-2",
})``;

export const CarrouselCard = styled.div.attrs({
  className: "w-80  shadow-xl rounded-none",
})``;

export const CarrouselCardTitle = styled.h2.attrs({
  className: "card-title self-center text-chinwhite",
})``;

export const CarrouselContent = styled.div.attrs({
  className:
    "carousel place-content-around carousel-center w-full p-4 space-x-6",
})``;

export const RegulacionCollapse = styled.div.attrs({
  className: "collapse collapse-arrow w-full py-1 text-start",
})`
  font-family: "Barlow";
`;

export const CollapseTitle = styled.div.attrs({
  className:
    "collapse-title text-xl uppercase font-bold text-start bg-chinblue text-primary-content peer-checked:bg-chinblack peer-checked:text-secondary-content",
})`
  font-family: "Barlow";
`;
export const CollapseContent = styled.div.attrs({
  className:
    "collapse-content -mx-4 -mb-5 leading-4 text-start bg-chinblue text-primary-content peer-checked:bg-chinblack peer-checked:text-secondary-content",
})`
  font-family: "Barlow";
`;
export const ContentTable = styled.div.attrs({
  className: "flex grid lg:grid-cols-5",
})`
  font-family: "Barlow";
`;

export const CountryA = styled.h4.attrs({
  className:
    "self-center pl-4 lg:pl-0 lg:text-center py-2 bg-chinyellow uppercase font-bold text-chinblack",
})``;

export const CountryB = styled.h4.attrs({
  className:
    "self-center pl-4 lg:pl-0 lg:text-center py-2 bg-chinyellow lg:bg-chingrey uppercase font-bold text-chinblack",
})``;

export const ContentB = styled.p.attrs({
  className: "self-center pb-4 text-start px-4 py-2 bg-zinc-700 text-chinwhite",
})``;

export const ContentA = styled.p.attrs({
  className:
    "self-center pb-4 text-start px-4 pt-2 bg-zinc-700 lg:bg-chinblack text-chinwhite",
})``;
